<template>
  <div class="info">
    <div id="watermark">
      <van-form>
        <template v-if="changeType == 1">
          <van-cell-group class="info-second">
            <van-cell class="custom">
              <template #title>
                <span class="custom-title">基本信息</span>
                <template v-if="!!dataForm.id">
                  <van-button class="check" type="info" size="small" round @click="dataCheck" v-if="show.allCheck == 0">核查</van-button>
                  <van-button type="info" size="small" round @click="control.disable1=!control.disable1" v-if="control.disable1 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="submit" v-if="control.disable1 == false">保存</van-button>
                </template>
                <div class="arrowIcon" @click="control.firstVisible=!control.firstVisible"><van-icon :name="control.firstVisible?'arrow-up':'arrow-down'"></van-icon></div>
              </template>
            </van-cell>
            <template v-if="control.firstVisible">
              <div class="scan-title" v-if="control.disable1==false">
                <div>扫描身份证件可自动生成个人信息</div>
                <van-uploader :after-read="cardIdIdentified">
                  <img :src="require('@/assets/img/scan.png')" alt="" class="scan">
                </van-uploader>
              </div>
              <template v-if="isAdd">
                <van-cell
                    class="required"
                    readonly
                    clickable
                    name="证件类型"
                    :value="show.idNumberTypeStr"
                    :value-class="{'value-common':show.idNumberTypeStr=='请选择'}"
                    title="证件类型"
                    placeholder="点击选择证件类型"
                    @click="showIdNumberType"
                    :is-link="!control.disable1"
                />

                <van-cell title="证件号码" class="required" v-if="dataForm.idNumberType !== 7">
                  <van-field
                      clearable
                      :readonly="control.disable1"
                      @blur="getInfoByIdNumber"
                      v-model="dataForm.idNumber"
                      name="证件号码"
                      placeholder="请输入有效证件号"
                      :rules="[{ required: true }]"
                  />
                </van-cell>
              </template>
              <div class="headImgBtn">
                <van-uploader :disabled="control.disable1" :after-read="headImgButton">
                  <van-cell title="上传照片" :title-style="{'color': '#4581F8'}">
                    <template>
                      <van-image v-if="show.headImg" class="headPng" fit="cover" :src="show.headImg" />
                    </template>
                  </van-cell>
                </van-uploader>
              </div>
              <van-cell title="姓名" class="required">
                <van-field
                    clearable
                    clear-trigger="always"
                    :readonly="control.disable1"
                    v-model="dataForm.name"
                    name="姓名"
                    placeholder="请输入姓名或者拍照识别"
                    :rules="[{ required: true }]"
                />
              </van-cell>
              <van-cell title="曾用名">
                <van-field
                    clearable
                    clear-trigger="always"
                    :readonly="control.disable1"
                    v-model="dataForm.usedName"
                    name="曾用名"
                    placeholder="请输入曾用名"
                    :rules="[{ required: true }]"
                />
              </van-cell>
              <van-cell name="性别" title="性别"
                        :value-class="{'value-common':dataForm.sex=='请选择'}"
                        :value="dataForm.sex == 1 ? '男' : '女'" :rules="[{ required: true, message: '请选择性别' }]"
                        @click="control.sex = !control.disable1"
                        readonly
                        clickable
                        placeholder="请选择性别"
                        :is-link="!control.disable1"
              />
              <van-popup v-model="control.sex" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="[{label: '男', value: 1}, {label: '女', value: 2}]"
                    @confirm="sexConfirm"
                    @cancel="control.sex = false"
                />
              </van-popup>

              <van-cell
                  readonly
                  clickable
                  name="出生年月"
                  :value="show.birthdayStr"
                  title="出生年月"
                  placeholder="请选择出生年月"
                  :value-class="{'value-common':show.birthdayStr=='请选择'}"
                  :rules="[{ required: true }]"
                  @click="showBirthday"
                  :is-link="!control.disable1"
              />
              <van-popup v-model="control.birthday" position="bottom">
                <van-datetime-picker v-model="currentBirthDay" type="date" title="请选择"
                                     @cancel="control.birthday = false" @confirm="birthdayCalendar" :min-date="birthdayMin" :formatter="formatter"/>
              </van-popup>
<!--              <van-calendar v-model="control.birthday" :show-confirm="false" color="#1989fa"-->
<!--                            :default-date="new Date(1997,0,1)" :min-date="new Date(1945,0,1)" :max-date="new Date()"-->
<!--                            @confirm="birthdayCalendar"/>-->
              <van-cell title="联系电话" :class="{required: dataForm.death != 1}">
                <template #title>
                  <span>联系电话</span>
<!--                  <button  class="inspect" @click="inspect('mobileCheck', '手机号')" :style="`background-color: ${dataForm.mobileCheck == 1 ? '#409EFF' : '#F56C6C'}`">-->
<!--                    {{dataForm.mobileCheck == 1 ? '已核查' : '未核查'}}-->
<!--                  </button>-->
                </template>
                <van-field
                    clearable
                    :readonly="control.disable1"
                    v-model="dataForm.mobile"
                    name="联系电话"
                    placeholder="请输入联系电话"
                    :rules="[{ required: true }]"
                />
              </van-cell>
              <van-cell title="固定电话">
                <van-field
                    clearable
                    :readonly="control.disable1"
                    v-model="dataForm.fixedLine"
                    name="固定电话"
                    placeholder="请输入固定电话"
                />
              </van-cell>

              <template v-if="!isAdd">
                <van-cell
                    class="required"
                    readonly
                    clickable
                    name="证件类型"
                    :value="show.idNumberTypeStr"
                    :value-class="{'value-common':show.idNumberTypeStr=='请选择'}"
                    title="证件类型"
                    placeholder="点击选择证件类型"
                    @click="showIdNumberType"
                    :is-link="!control.disable1"
                />

                <van-cell title="证件号码" class="required" v-if="dataForm.idNumberType !== 7">
                  <van-field
                      clearable
                      :readonly="control.disable1"
                      @blur="getInfoByIdNumber"
                      v-model="dataForm.idNumber"
                      name="证件号码"
                      placeholder="请输入有效证件号"
                      :rules="[{ required: true }]"
                  />
                </van-cell>
              </template>
              <van-popup v-model="control.idNumberType" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.idNumberTypeList"
                    @confirm="idNumberType"
                    @cancel="control.idNumberType = false"
                />
              </van-popup>
              <van-cell
                  class="required"
                  readonly
                  clickable
                  :value="show.nationStr"
                  :value-class="{'value-common':show.nationStr=='请选择'}"
                  name="民族"
                  title="民族"
                  placeholder="请选择民族"
                  @click="goNation"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable1"
              />

              <van-cell
                  class="required"
                  readonly
                  clickable
                  :value-class="{'value-common':show.nationalityStr=='请选择'}"
                  :value="show.nationalityStr"
                  name="国籍"
                  title="国籍"
                  placeholder="请选择国籍"
                  @click="goNationality"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable1"
              />

              <van-field
                  :readonly="control.disable1"
                  clickable
                  v-model="dataForm.hometown"
                  :value-class="{'value-common':show.Hometown=='请选择'}"
                  name="籍贯"
                  label="籍贯"
                  placeholder="请输入籍贯"
                  :rules="[{ required: true }]"
              />
              <van-popup v-model="control.Hometown" position="bottom">
                <van-cascader title="请选择" :options="selectList.hometownList"
                              @close="control.Hometown = false" @finish="hometownFinish" :field-names="fieldNames"/>
              </van-popup>

              <van-cell name="户籍地省市区" title="户籍地省市区" :value="show.regAddressPcc||'请选择'"
                        :value-class="{'value-common':show.regAddressPcc==''}" :is-link="!control.disable1" clickable
                        @click="control.regAddressPcc = true" placeholder="请选择户籍地省市区" />

              <van-popup v-model="control.regAddressPcc" position="bottom"
                         @click-overlay="regAddressPccPccOverlay">
                <van-cascader
                    title="请选择"
                    value="value"
                    :field-names="{ text: 'name', value: 'name', children: 'districts' }"
                    :options="cityData"
                    active-color="#1989fa"
                    @close="regAddressPccPccClose"
                    @change="regAddressPccFinish"
                />
              </van-popup>
              <van-cell v-if="codeType == 2">
                <template #title>
                  <span>户籍标准地址名称</span>
                </template>

                <van-field
                    clearable
                    :readonly="control.disable1"
                    v-model="dataForm.regAddressCodeName"
                    type="textarea"
                    autosize
                    rows="1"
                    name="户籍标准地址名称"
                    placeholder="请输入户籍标准地址名称"
                    @blur="dataForm.regAddress = dataForm.regAddressCodeName"
                />
              </van-cell>
              <p v-if="isTipShow" style="line-height: 0.3rem;margin: 0.1rem 0 0 0.5rem;color: #F56C6C;font-size: 14px;">{{tipValue}}</p>
              <van-cell title="户籍标准地址码" v-if="codeType == 2" :value="show.regCodeStr || '请选择'" @click="control.disable1 ? false : getCode($event, 1)"
                        :value-class="{'value-common':show.regCodeStr=='请选择'}" :is-link="!control.disable1"/>
              <van-popup
                  v-model="control.regCodeShow"
                  position="bottom">
                <van-picker
                    title="请选择"
                    show-toolbar
                    :columns="selectList.regCodeList"
                    value-key="label"
                    @confirm="codeConfirm($event, 1)"
                    @cancel="control.regCodeShow = false"
                />
              </van-popup>
<!--              <van-cell v-if="codeType == 2">-->
<!--                <template #title>-->
<!--                  <span>户籍标准地址码</span>-->
<!--                </template>-->

<!--                <van-field-->
<!--                    clearable-->
<!--                    :readonly="control.disable1"-->
<!--                    v-model="dataForm.regAddressCode"-->
<!--                    type="textarea"-->
<!--                    autosize-->
<!--                    rows="1"-->
<!--                    name="户籍标准地址码"-->
<!--                    placeholder="请输入户籍标准地址码"-->
<!--                />-->
<!--              </van-cell>-->
              <van-cell>
                <template #title>
                  <span>户籍地址</span>
                  <!--                  <button  class="inspect" @click="inspect('regAddressCheck', '户籍地址')" :style="`background-color: ${dataForm.regAddressCheck == 1 ? '#409EFF' : '#F56C6C'}`">-->
                  <!--                    {{dataForm.regAddressCheck == 1 ? '已' : '未'}}核查-->
                  <!--                  </button>-->
                </template>

                <van-field
                    clearable
                    :readonly="control.disable1"
                    v-model="dataForm.regAddress"
                    type="textarea"
                    autosize
                    rows="1"
                    name="户籍地址"
                    placeholder="请输入户籍地址"
                />
              </van-cell>

<!--              <van-cell v-if="codeType == 2">-->
<!--                <template #title>-->
<!--                  <span>现居标准地址码</span>-->
<!--                </template>-->

<!--                <van-field-->
<!--                    clearable-->
<!--                    :readonly="control.disable1"-->
<!--                    v-model="dataForm.nowAddressCode"-->
<!--                    type="textarea"-->
<!--                    autosize-->
<!--                    rows="1"-->
<!--                    name="现居标准地址码"-->
<!--                    placeholder="请输入现居标准地址码"-->
<!--                />-->
<!--              </van-cell>-->

              <van-cell name="现居地省市区" title="现居地省市区" :value="show.nowAddressPcc||'请选择'"
                        :value-class="{'value-common':show.nowAddressPcc==''}" :is-link="!control.disable1" clickable
                        @click="control.nowAddressPcc = true" placeholder="请选择现居地省市区" />
              <van-popup v-model="control.nowAddressPcc" position="bottom"
                         @click-overlay="nowAddressPccOverlay">
                <van-cascader
                    title="请选择"
                    value="value"
                    :field-names="{ text: 'name', value: 'name', children: 'districts' }"
                    :options="cityData"
                    active-color="#1989fa"
                    @close="nowAddressPccClose"
                    @change="nowAddressPccFinish"
                />
              </van-popup>
              <van-cell v-if="codeType == 2">
                <template #title>
                  <span>现居标准地址名称</span>
                </template>

                <van-field
                    clearable
                    :readonly="control.disable1"
                    v-model="dataForm.nowAddressCodeName"
                    type="textarea"
                    autosize
                    rows="1"
                    name="现居标准地址名称"
                    placeholder="请输入现居标准地址名称"
                    @blur="dataForm.nowAddress = dataForm.nowAddressCodeName"
                />
              </van-cell>
              <p v-if="isNowAddressTipShow" style="line-height: 0.3rem;margin: 0.1rem 0 0 0.5rem;color: #F56C6C;font-size: 14px;">{{nowAddressTipValue}}</p>
              <van-cell title="现居标准地址码" v-if="codeType == 2" :value="show.nowCodeStr || '请选择'" @click="control.disable1 ? false : getCode($event, 2)"
                        :value-class="{'value-common':show.nowCodeStr=='请选择'}" :is-link="!control.disable1"/>
              <van-popup
                  v-model="control.nowCodeShow"
                  position="bottom">
                <van-picker
                    title="请选择"
                    show-toolbar
                    :columns="selectList.nowCodeList"
                    value-key="label"
                    @confirm="codeConfirm($event, 2)"
                    @cancel="control.nowCodeShow = false"
                />
              </van-popup>
              <van-cell title="现居地">
                <template #title>
                  <span>现居地址</span>
                  <!--                  <button  class="inspect" @click="inspect('nowAddressCheck', '现居地')" :style="`background-color: ${dataForm.nowAddressCheck == 1 ? '#409EFF' : '#F56C6C'}`">-->
                  <!--                    {{dataForm.nowAddressCheck == 1 ? '已' : '未'}}核查-->
                  <!--                  </button>-->
                </template>
                <van-field
                    clearable
                    :readonly="control.disable1"
                    v-model="dataForm.nowAddress"
                    name="现居地"
                    type="textarea"
                    autosize
                    rows="1"
                    placeholder="请输入现居地址"
                />
              </van-cell>
            </template>
          </van-cell-group>

          <!-- 所属社区 -->
          <van-cell-group class="info-second">
            <van-cell class="custom">
              <template #title>
                <span class="custom-title">所属社区</span>
                <template v-if="!!dataForm.id">
                  <van-button type="info" size="small" round @click="control.disable2=!control.disable2" v-if="control.disable2 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="submit" v-if="control.disable2 == false">保存</van-button>
                </template>
                <div class="arrowIcon" @click="control.secondVisible=!control.secondVisible"><van-icon :name="control.secondVisible?'arrow-up':'arrow-down'"></van-icon></div>
              </template>
            </van-cell>
            <template v-if="control.secondVisible">
              <template v-if="((!dataForm.id ) || (dataForm.id && !userRes)) && orgDepth == 4">
                <van-cell
                    class="required"
                    readonly
                    clickable
                    name="所属社区"
                    :value="show.orgStr"
                    title="所属社区"
                    :value-class="{'value-common':show.orgStr=='请选择'}"
                    placeholder="请选择所属社区"
                    :rules="[{ required: true }]"
                    @click="showOrg"
                    :is-link="!control.disable2"
                />
                  <van-popup v-model="control.orgShow" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="label"
                        :columns="selectList.orgList"
                        @confirm="orgConfirm"
                        @cancel="control.orgShow = false"
                    />
                  </van-popup>
                <van-cell
                    class="required"
                    readonly
                    clickable
                    name="户籍类型"
                    :value="show.registryTypeStr  || item.registryTypeStr"
                    title="户籍类型"
                    :value-class="{'value-common':show.registryTypeStr=='请选择'}"
                    placeholder="请选择户籍类型"
                    :rules="[{ required: true }]"
                    @click="showRegistryType"
                    :is-link="!control.disable2"
                >
                  <template #title>
                    <span>户籍类型</span>
                    <van-icon v-if="show.registryTypeStr != '请选择'" @click.stop="showHouseTypeDes = !showHouseTypeDes" style="margin-left: 15px" name="question-o" size=".5rem" color="#ccc" />
                  </template>
                </van-cell>
                <div class="houseTypeDes" v-if="showHouseTypeDes">
                  {{registryContent1[show.registryTypeStr]}}
                </div>
                <van-cell title="入住时间" :value="dataForm.moveInDate || '请选择'" @click="!control.disable2 ? control.moveInDate = true : false"
                          :value-class="{'value-common': !show.moveInDate}" :is-link="!control.disable2" />
                <van-popup v-model="control.moveInDate" position="bottom">
                  <van-datetime-picker v-model="show.moveInDate" type="date" title="请选择"
                                       @cancel="control.moveInDate = false" @confirm="moveInDateConfirm" :min-date="minDate1" />
                </van-popup>

                <van-popup v-model="control.registryType" position="bottom">
                  <van-picker
                      show-toolbar
                      value-key="label"
                      :columns="selectList.registryTypeList"
                      @confirm="registryType"
                      @cancel="control.registryType = false"
                  />
                </van-popup>

                <van-cell
                    readonly
                    clickable
                    name="标签管理"
                    :value="show.labelStr || '请选择'"
                    title="标签管理"
                    :is-link="!control.disable2"
                    @click="showlabel"
                />
                <van-popup v-model="control.label" position="bottom"
                           @click-overlay="labelOverlay">
                  <van-cascader
                      title="请选择"
                      value="value"
                      :field-names="{ text: 'label', value: 'value', children: 'children' }"
                      :options="selectList.userCommunityLabelTree"
                      active-color="#1989fa"
                      @close="labelClose"
                      @change="labelFinish"
                  />
                </van-popup>

                <van-cell hover-class="none" v-if="labels.length>0">
                  <template v-for="item in labels">
                    <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
                      <span :style="control.disabled2 ? 'border: none' : ''">{{ item.name }}</span>
                      <van-icon style="padding: 0 10px" name="cross" v-if="!control.disabled2" @click="removeLabel(item.value)"/>
                    </div>
                  </template>

                </van-cell>
              </template>
              <template v-else>
                <van-tabs v-model="active" color="#4581F8" title-active-color="#4581F8">
                  <van-tab :title-style="{'fontSize': '16px'}" v-for="(item, index) in dataForm.communityList" :key="index" :title="item.orgName">
                    <van-cell
                        class="required"
                        readonly
                        clickable
                        name="户籍类型"
                        :value="show.registryTypeStr  || item.registryTypeStr"
                        title="户籍类型"
                        :value-class="{'value-common':show.registryTypeStr=='请选择'}"
                        placeholder="请选择户籍类型"
                        :rules="[{ required: true }]"
                        @click="showRegistryType"
                        :is-link="!control.disable2"
                    >
                      <template #title>
                        <span>户籍类型</span>
                        <van-icon v-if="show.registryTypeStr != '请选择'" @click.stop="showHouseTypeDes = !showHouseTypeDes" style="margin-left: 15px" name="question-o" size=".5rem" color="#ccc" />
                      </template>
                    </van-cell>
                    <div class="houseTypeDes" v-if="showHouseTypeDes">
                      {{registryContent1[show.registryTypeStr]}}
                    </div>
                    <van-cell title="入住时间" :value="dataForm.moveInDate || item.moveInDateStr || '请选择'" @click="!control.disable2 ? control.moveInDate = true : false"
                              :value-class="{'value-common': !show.moveInDate}" :is-link="!control.disable2" />
                    <van-popup v-model="control.moveInDate" position="bottom">
                      <van-datetime-picker v-model="show.moveInDate" type="date" title="请选择"
                                           @cancel="control.moveInDate = false" @confirm="moveInDateConfirm" :min-date="minDate1" />
                    </van-popup>

                    <van-popup v-model="control.registryType" position="bottom">
                      <van-picker
                          show-toolbar
                          value-key="label"
                          :columns="selectList.registryTypeList"
                          @confirm="registryType"
                          @cancel="control.registryType = false"
                      />
                    </van-popup>

                    <van-cell
                        readonly
                        clickable
                        name="标签管理"
                        :value="show.labelStr || '请选择'"
                        title="标签管理"
                        :is-link="!control.disable2"
                        @click="showlabel"
                    />
                    <van-popup v-model="control.label" position="bottom"
                               @click-overlay="labelOverlay">
                      <van-cascader
                          title="请选择"
                          value="value"
                          :field-names="{ text: 'label', value: 'value', children: 'children' }"
                          :options="selectList.userCommunityLabelTree"
                          active-color="#1989fa"
                          @close="labelClose"
                          @change="labelFinish"
                      />
                    </van-popup>

                    <van-cell hover-class="none" v-if="labels.length>0">
                      <template v-for="item in labels">
                        <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
                          <span :style="control.disable2 ? 'border: none' : ''">{{ item.name }}</span>
                          <van-icon style="padding: 0 10px" name="cross" v-if="!control.disable2" @click="removeLabel(item.value)"/>
                        </div>
                      </template>

                    </van-cell>
                  </van-tab>
                </van-tabs>
              </template>
            </template>
          </van-cell-group>

          <!-- 附属信息 -->
          <van-cell-group class="info-second">
            <van-cell class="custom">
              <template #title>
                <span class="custom-title">附属信息</span>
                <template v-if="!!dataForm.id">
                  <van-button type="info" size="small" round @click="control.disable3=!control.disable3" v-if="control.disable3 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="submit" v-if="control.disable3 == false">保存</van-button>
                </template>
                <div class="arrowIcon" @click="control.thirdVisible=!control.thirdVisible"><van-icon :name="control.thirdVisible?'arrow-up':'arrow-down'"></van-icon></div>
              </template>
            </van-cell>
            <template v-if="control.thirdVisible">
              <van-cell title="出生证明编码">
                <van-field
                    clearable
                    :readonly="control.disable3"
                    v-model="dataForm.birthdayCode"
                    name="出生证明编码"
                    placeholder="请输入出生证明编码"
                />
              </van-cell>

              <van-cell
                  :readonly="control.disable3"
                  v-model="show.political"
                  name="政治面貌"
                  title="政治面貌"
                  :value-class="{'value-common':show.political=='请选择'}"
                  @click="showPolitical"
                  placeholder="请选择政治面貌"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable3"
              />
              <van-popup v-model="control.political" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.politicalList"
                    @confirm="politicalConfirm"
                    @cancel="control.political = false"
                />
              </van-popup>

              <van-cell
                  :readonly="control.disable3"
                  v-model="show.healthStr"
                  name="健康状况"
                  title="健康状况"
                  :value-class="{'value-common':show.healthStr=='请选择'}"
                  @click="showHealth"
                  placeholder="请选择健康状况"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable3"
              />
              <van-popup v-model="control.healthShow" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.healthList"
                    @confirm="healthConfirm"
                    @cancel="control.healthShow = false"
                />
              </van-popup>

<!--              <van-cell-->
<!--                  :readonly="control.disable3"-->
<!--                  v-model="show.spouseIdNumberType"-->
<!--                  @click="showspouseIdNumberType"-->
<!--                  name="配偶证件类型"-->
<!--                  title="配偶证件类型"-->
<!--                  :value-class="{'value-common':show.spouseIdNumberType=='请选择'}"-->
<!--                  placeholder="请选择配偶证件类型"-->
<!--                  :rules="[{ required: true }]"-->
<!--                  :is-link="!control.disable3"-->
<!--              />-->
<!--              <van-popup v-model="control.spouseIdNumberType" position="bottom">-->
<!--                <van-picker-->
<!--                    show-toolbar-->
<!--                    value-key="label"-->
<!--                    :columns="selectList.idNumberTypeList"-->
<!--                    @confirm="spouseIdNumberTypeConfirm"-->
<!--                    @cancel="control.spouseIdNumberType = false"-->
<!--                />-->
<!--              </van-popup>-->
<!--              <van-cell title="配偶证件号">-->
<!--                <van-field-->
<!--                    clearable-->
<!--                    :readonly="control.disable3"-->
<!--                    v-model="dataForm.spouseIdNumber"-->
<!--                    name="配偶证件号"-->
<!--                    placeholder="请输入配偶证件号"-->
<!--                />-->
<!--              </van-cell>-->

              <van-cell
                  :readonly="control.disable3"
                  v-model="show.education"
                  name="学历"
                  title="学历"
                  :value-class="{'value-common':show.education=='请选择'}"
                  placeholder="请选择学历"
                  @click="showEducation"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable3"
              />
              <van-popup v-model="control.education" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.educationList"
                    @confirm="educationConfirm"
                    @cancel="control.education = false"
                />
              </van-popup>

              <van-cell title="所学专业">
                <van-field
                    clearable
                    :readonly="control.disable3"
                    v-model="dataForm.specialty"
                    name="所学专业"
                    placeholder="请输入所学专业"
                />
              </van-cell>

              <van-cell
                  :readonly="control.disable3"
                  v-model="show.jobType"
                  name="就业情况"
                  title="就业情况"
                  :value-class="{'value-common':show.jobType=='请选择'}"
                  placeholder="请选择就业情况"
                  @click="showJobType"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable3"
              />
              <van-popup v-model="control.jobType" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.jobTypeList"
                    @confirm="jobTypeConfirm"
                    @cancel="control.jobType = false"
                />
              </van-popup>

              <van-cell title="是否死亡">
                <van-switch v-model="death" :disabled="control.disable3"/>
              </van-cell>
              <van-popup
                  position="bottom"
                  custom-style="height: 20%;"
                  title="死亡信息"
                  v-model="showdeath">
                <van-cell title="死亡信息" title-style="color:black !important;" size="large" />
                <div class="text">     <van-field
                    readonly
                    clickable
                    name="死亡时间"
                    :value="dataForm.deathDate"
                    label="死亡时间"
                    placeholder="点击选择死亡时间"
                    @click="showDeathDate"
                />
                  <van-calendar v-model="control.deathDate" :default-date="new Date()" :min-date="new Date(2000,0,1)"
                                :max-date="new Date()" :show-confirm="false" color="#1989fa" @confirm="deathDateCalendar"/>

                  <van-field
                      :readonly="control.disable5"
                      v-model="dataForm.deathCard"
                      clearable
                      name="死亡证明编号"
                      label="死亡证明编号"
                      placeholder="请输入死亡证明编号"
                  />

                  <van-field
                      readonly
                      clickable
                      name="火化时间"
                      :value="dataForm.cremationDate"
                      label="火化时间"
                      placeholder="点击选择火化时间"
                      @click="showCremationDate"
                  />
                  <van-calendar v-model="control.cremationDate" :default-date="new Date()" :min-date="new Date(2000,0,1)"
                                :max-date="new Date()" :show-confirm="false" color="#1989fa" @confirm="cremationDateCalendar"/>

                  <van-field
                      :readonly="control.disable5"
                      v-model="dataForm.cremationCard"
                      clearable
                      name="火化证件编号"
                      label="火化证件编号"
                      placeholder="请输入火化证件编号"
                  />

                  <van-field
                      :readonly="control.disable5"
                      v-model="dataForm.deathAddress"
                      clearable
                      name="死亡地点"
                      label="死亡地点"
                      placeholder="请输入死亡地点"
                  />

                  <van-field
                      :readonly="control.disable5"
                      v-model="dataForm.deathReason"
                      clearable
                      name="死亡原因"
                      label="死亡原因"
                      placeholder="请输入死亡原因"
                  />
                </div>
                <div style="margin-top: 30px;margin-bottom:20px">
                  <van-button type="info"  style="width:90%;margin-left:5%" @click="showdeath=false">确定</van-button>
                </div>

              </van-popup>

              <van-cell
                  :value="show.marriage"
                  name="婚姻"
                  title="婚姻"
                  placeholder="请选择是否已婚"
                  :value-class="{'value-common':show.marriage=='请选择'}"
                  @click="showMarriage"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable3"
              />
              <van-popup v-model="control.marriage" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.marriageList"
                    @confirm="marriageConfirm"
                    @cancel="control.marriage = false"
                />
              </van-popup>

              <van-cell
                  :readonly="control.disable3"
                  v-model="show.militaryTypeStr"
                  name="服兵役情况"
                  title="服兵役情况"
                  :value-class="{'value-common':show.militaryTypeStr=='请选择'}"
                  @click="showMilitaryType"
                  placeholder="请选择服兵役情况"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable3"
              />
              <van-popup v-model="control.militaryTypeShow" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.militaryTypeList"
                    @confirm="militaryTypeConfirm"
                    @cancel="control.militaryTypeShow = false"
                />
              </van-popup>


              <van-cell
                  :readonly="control.disable3"
                  v-model="show.religious"
                  name="宗教信仰"
                  title="宗教信仰"
                  @click="showReligious"
                  :value-class="{'value-common':show.religious=='请选择'}"
                  placeholder="请选择宗教信仰"
                  :rules="[{ required: true }]"
                  :is-link="!control.disable3"
              />
              <van-popup v-model="control.religious" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.religiousList"
                    @confirm="religiousConfirm"
                    @cancel="control.religious = false"
                />
              </van-popup>

              <van-cell title="紧急联系人">
                <van-field
                    clearable
                    :readonly="control.disable3"
                    v-model="dataForm.emContact"
                    name="紧急联系人"
                    placeholder="请输入紧急联系人"
                />
              </van-cell>
              <van-cell title="联系人手机号">
                <van-field
                    clearable
                    :readonly="control.disable3"
                    v-model="dataForm.emMobile"
                    name="联系人手机号"
                    placeholder="请输入联系人手机号"
                />
              </van-cell>
              <van-cell title="卡片名称" :border="false" class="cellClass" />
              <van-field
                  clearable
                  :readonly="control.disable3"
                  v-model="dataForm.cardName"
                  name="卡片名称"
                  placeholder="请输入卡片名称"
              />
              <van-cell title="开卡人" :border="false" class="cellClass" />
              <van-field
                  clearable
                  :readonly="control.disable3"
                  v-model="dataForm.cardOwnerName"
                  name="开卡人"
                  placeholder="请输入开卡人姓名"
              />
              <van-cell title="卡号" :border="false" class="cellClass" />
              <van-field
                  clearable
                  :readonly="control.disable3"
                  v-model="dataForm.cardNumber"
                  name="卡号"
                  placeholder="请输入卡号"
              />
              <van-cell title="备注" :border="false" class="cellClass" />
              <van-field
                  clearable
                  clear-trigger="always"
                  :readonly="control.disable3"
                  :border="false"
                  class="left"
                  v-model="dataForm.resRemark"
                  rows="4"
                  autosize
                  type="textarea"
                  placeholder="请输入备注"
                  input-align="left"
              />
            </template>
          </van-cell-group>

          <!-- 工作信息 -->
          <van-cell-group class="info-second">
            <van-cell class="custom">
              <template #title>
                <span class="custom-title">工作信息</span>
                <template v-if="!!dataForm.id">
                  <van-button type="info" size="small" round @click="control.disable4=!control.disable4" v-if="control.disable4 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="submit" v-if="control.disable4 == false">保存</van-button>
                </template>
                <div class="arrowIcon" @click="control.fourthVisible=!control.fourthVisible"><van-icon :name="control.fourthVisible?'arrow-up':'arrow-down'"></van-icon></div>
              </template>
            </van-cell>
            <template v-if="control.fourthVisible">
              <van-field
                  :readonly="control.disable4"
                  v-model="dataForm.job"
                  name="职业"
                  clearable
                  label="职业"
                  placeholder="请输入职业"
              />
              <van-field

                  :readonly="control.disable4"
                  v-model="dataForm.company"
                  name="工作单位"
                  clearable
                  label="工作单位"
                  placeholder="请输入工作单位"
              />

              <van-cell
                  v-show="dataForm.jobType != 4"
                  :readonly="control.disable4"
                  v-model="show.insuredStatus"
                  name="参保状态"
                  title="参保状态"
                  @click="showInsuredStatus"
                  :value-class="{'value-common':show.insuredStatus=='请选择'}"
                  placeholder="请选择参保状态"
                  :is-link="!control.disable4"
              />
              <van-popup v-model="control.insuredStatus" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.insuredStatusList"
                    @confirm="insuredStatusConfirm"
                    @cancel="control.insuredStatus = false"
                />
              </van-popup>

              <van-field
                  v-show="dataForm.jobType == 2 || dataForm.jobType == 3"
                  :readonly="control.disable4"
                  v-model="dataForm.unemploymentReason"
                  clearable
                  name="失业原因"
                  label="失业原因"
                  placeholder="请输入失业原因"
              />

              <van-cell
                  v-show="dataForm.jobType == 4"
                  :readonly="control.disable4"
                  v-model="show.retirementType"
                  name="退休类别"
                  title="退休类别"
                  @click="showRetirementType"
                  :value-class="{'value-common':show.retirementType=='请选择'}"
                  placeholder="请选择退休类别"
                  :is-link="!control.disable4"
              />
              <van-popup v-model="control.retirementType" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.retirementTypeList"
                    @confirm="retirementTypeConfirm"
                    @cancel="control.retirementType = false"
                />
              </van-popup>

              <van-field
                  v-show="dataForm.jobType == 4"
                  :readonly="control.disable4"
                  v-model="dataForm.retirementReason"
                  clearable
                  name="退休原因"
                  label="退休原因"
                  placeholder="请输入退休原因"
              />

              <van-cell
                  v-show="dataForm.jobType == 4"
                  :readonly="control.disable4"
                  v-model="show.pensionStr"
                  name="是否领取养老金"
                  title="是否领取养老金"
                  @click="showPension"
                  :value-class="{'value-common':show.pensionStr=='请选择'}"
                  placeholder="请选择是否领取养老金"
                  :is-link="!control.disable4"
              />
              <van-popup v-model="control.pensionShow" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="[{value: 1, label: '是'}, {value: 0, label: '否'}]"
                    @confirm="pensionConfirm"
                    @cancel="control.pensionShow = false"
                />
              </van-popup>

            </template>
          </van-cell-group>

          <van-cell-group class="info-second" v-if="dataForm.death == 1">
            <van-cell class="custom">
              <template #title>
                <span class="custom-title">死亡信息</span>
                <template v-if="!!dataForm.id">
                  <van-button type="info" size="small" round @click="control.disable5=!control.disable5" v-if="control.disable5 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="submit" v-if="control.disable5 == false">保存</van-button>
                </template>
                <div class="arrowIcon" @click="control.fiveVisible=!control.fiveVisible"><van-icon :name="control.fiveVisible?'arrow-up':'arrow-down'"></van-icon></div>
              </template>
            </van-cell>

            <template v-if="control.fiveVisible">
              <van-field
                  readonly
                  clickable
                  name="死亡时间"
                  :value="dataForm.deathDate"
                  label="死亡时间"
                  placeholder="点击选择死亡时间"
                  @click="showDeathDate"
              />
              <van-calendar v-model="control.deathDate" :default-date="new Date()" :min-date="new Date(2000,0,1)"
                            :max-date="new Date()" :show-confirm="false" color="#1989fa" @confirm="deathDateCalendar"/>

              <van-field
                  :readonly="control.disable5"
                  v-model="dataForm.deathCard"
                  clearable
                  name="死亡证明编号"
                  label="死亡证明编号"
                  placeholder="请输入死亡证明编号"
              />

              <van-field
                  readonly
                  clickable
                  name="火化时间"
                  :value="dataForm.cremationDate"
                  label="火化时间"
                  placeholder="点击选择火化时间"
                  @click="showCremationDate"
              />
              <van-calendar v-model="control.cremationDate" :default-date="new Date()" :min-date="new Date(2000,0,1)"
                            :max-date="new Date()" :show-confirm="false" color="#1989fa" @confirm="cremationDateCalendar"/>

              <van-field
                  :readonly="control.disable5"
                  v-model="dataForm.cremationCard"
                  clearable
                  name="火化证件编号"
                  label="火化证件编号"
                  placeholder="请输入火化证件编号"
              />

              <van-field
                  :readonly="control.disable5"
                  v-model="dataForm.deathAddress"
                  clearable
                  name="死亡地点"
                  label="死亡地点"
                  placeholder="请输入死亡地点"
              />

              <van-field
                  :readonly="control.disable5"
                  v-model="dataForm.deathReason"
                  clearable
                  name="死亡原因"
                  label="死亡原因"
                  placeholder="请输入死亡原因"
              />
            </template>
          </van-cell-group>

          <!--  自定义字段   -->
          <van-cell-group class="info-second">
            <van-cell class="custom">
              <template #title>
                <span class="custom-title">自定义字段</span>
                <template v-if="!!dataForm.id">
                  <van-button type="info" size="small" round @click="control.disable6=!control.disable6" v-if="control.disable6 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="submit" v-if="control.disable6 == false">保存</van-button>
                </template>
                <div class="arrowIcon" @click="sixChange"><van-icon :name="control.sixVisible?'arrow-up':'arrow-down'"></van-icon></div>
              </template>
            </van-cell>
            <!-- 自定义字段 -->
<!--            <template v-if="control.sixVisible">-->
              <field-view ref="fieldView" v-if="control.sixVisible" @fatherChange="disableChange" :disabled="control.disable6" :id="dataForm.id"  @submit="submit"/>
<!--            </template>-->
          </van-cell-group>
        </template>
        <template v-if="changeType == 2">
            <div class="list-info houseInfo">
              <div class="addBtn" @click="goHouse(null)"
                @mousedown="down"
                @touchstart="down"
                @mousemove="move"
                @touchmove="move"
                @mouseup="end"
                @touchend="end"
                draggable="true"
                ref="fu" >
                <img src="@/assets/img/addHouse.png" alt="">
              </div>
              <div class="cont">
                <div class="list-item" v-for="(item, index) in resHouses" :key="index">
                  <div class="title">
                    <div class="title-left">
                      {{'房屋' + (index + 1)}}
                    </div>
                    <div class="title-right" style="color: #333;">
                      <span style="color: #666;" v-if="item.householdName">房主：</span>{{item.householdName}}<a v-if="item.householdMobile" :href="'tel:'+item.householdMobile" @click.stop=""><img src="@/assets/img/mobile.png" alt="" class="tel"/></a>
                    </div>
                  </div>
                  <div class="content" @click="selectInfo(item.id)">
                    <div class="content-text">
                      <div class="top-text">
                        <span style="float: left;">{{item.subareaName + ':' + item.houseFullName}}</span>
                        <van-popover
                            v-model="control.showPopover"
                            trigger="click"
                            placement="bottom-start"
                            class="gridPopover"
                            :actions="selectList.actions">
                          <template #reference>
                            <div class="tip" v-if="item.manage">网格管辖<img src="@/assets/img/question.png" alt=""></div>
                          </template>
                        </van-popover>
                      </div>
                      <div class="bottom-text">
                        <van-tag type="primary" text-color="#4581F8" color="rgba(78,124,245,0.12)" style="border-radius: 2px;padding: 3px 5px">{{item.useTypeStr}}</van-tag>
                        <van-tag type="primary" text-color="#4581F8" color="rgba(78,124,245,0.12)" style="border-radius: 2px;padding: 3px 5px;margin-left: 12px;">{{item.nowLive == 0 ? '不住' : item.nowLive == 1 ? '常住' : '偶尔居住'}}</van-tag>
                        <span style="margin-left: 12px">与房主关系：{{item.relationshipStr}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="content-bottom">
                    <div class="bottom-left" style="right:0">
                      <van-button size="small" style="background-color:transparent; color: #4581F8;border: 1px solid #4581F8;" @click="moveAway(item.id)">搬离</van-button>
                    </div>
                    <div class="bottom-right">
                      <van-button plain size="small" @click="gotohouse(index)" style="margin-right: 12px;">前往房屋</van-button>
                      <van-button size="small" @click="goHouse(item)">编辑</van-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        <template v-if="changeType == 3">
          <van-cell-group class="info-second">
            <van-cell class="custom relation">
              <template #title>
                <span class="custom-title">家庭关系</span>
                <template v-if="!!dataForm.id">
                  <van-button type="info" size="small" round @click="control.disable7=!control.disable7" v-if="control.disable7 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="submit" v-if="control.disable7 == false">保存</van-button>
                </template>
              </template>
            </van-cell>

            <van-field
                :readonly="control.disable7"
                v-model="dataForm.emContact"
                clearable
                name="紧急联系人"
                label="紧急联系人"
                placeholder="请输入"
                :rules="[{ required: true }]"
            />

            <van-field
                :readonly="control.disable7"
                v-model="dataForm.emMobile"
                clearable
                name="联系人电话"
                label="联系人电话"
                placeholder="请输入"
                :rules="[{ required: true }]"
            />
          </van-cell-group>

          <div class="list-info family">
            <div class="cont">
              <div class="list-item" v-for="item in selectList.familyList" :key="item.id">
                <div class="content" style="background-color: #fff;" >
                  <van-image :src="item.imgUrl||require('@/assets/img/header.png')" class="content-img">
                    <template slot="error">
                      <img :src="require('@/assets/img/header.png')" alt="" class="content-img">
                    </template>
                    <template slot="loading">
                      <img :src="require('@/assets/img/header.png')" alt="" class="content-img">
                    </template>
                  </van-image>
                  <div class="content-text" @click="gotoRelation(item, 1, false)">
                    <div class="top-text">{{item.relationAB}}</div>
                    <div class="bottom-text">{{item.name}} <span>{{item.mobile}}</span> <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/mobile.png" alt="" class="tel"/></a></div>
                  </div>
                  <van-icon name="arrow" color="#666" class="bottom-icon" v-if="!control.disable7" @click="!control.disable7 ? gotoRelation(item, 1, true) : false"/>
                </div>
              </div>
            </div>
          </div>
          <div class="relationAdd" @click="gotoRelation(null, 1, true)" v-if="!dataForm.id || !control.disable7">
            <img :src="require('@/assets/img/relation_add.png')" alt="" /> <span>添加家人</span>
          </div>
        </template>
        <template v-if="changeType == 4">
          <van-cell-group class="info-second">
            <van-cell class="custom relation">
              <template #title>
                <span class="custom-title">社会关系</span>
                <template v-if="!!dataForm.id">
                  <van-button type="info" size="small" round @click="control.disable8=!control.disable8" v-if="control.disable8 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="submit" v-if="control.disable8 == false">保存</van-button>
                </template>
              </template>
            </van-cell>
          </van-cell-group>

          <div class="list-info family">
            <div class="cont">
              <div class="list-item" v-for="item in selectList.socialList" :key="item.id">
                <div class="content" style="background-color: #fff;" >
                  <van-image :src="item.imgUrl||require('@/assets/img/header.png')" class="content-img">
                    <template slot="error">
                      <img :src="require('@/assets/img/header.png')" alt="" class="content-img">
                    </template>
                    <template slot="loading">
                      <img :src="require('@/assets/img/header.png')" alt="" class="content-img">
                    </template>
                  </van-image>
                  <div class="content-text" @click="gotoRelation(item, 2, false)">
                    <div class="top-text">{{item.relations}}</div>
                    <div class="bottom-text">{{item.relationsName}} {{item.relationsMobile}} <img class="tel" :src="require('@/assets/img/mobile.png')" @click.stop="getUserMobile(item.userId)" /></div>
                  </div>
                  <van-icon name="arrow" color="#666" class="bottom-icon" v-if="!control.disable8" @click="!control.disable8 ? gotoRelation(item, 2, true) : false"/>
                </div>
              </div>
            </div>
          </div>
          <div class="relationAdd" @click="gotoRelation(null, 2, true)" v-if="!dataForm.id || !control.disable8">
            <img :src="require('@/assets/img/relation_add.png')" alt="" /> <span>添加关系</span>
          </div>
        </template>
        <template v-if="changeType == 5 && !control.gridShow">
          <van-cell-group>
            <van-cell class="custom relation">
              <template #title>
                <span class="custom-title">网格信息</span>
                <van-button type="info" size="small" round @click="control.disable6=!control.disable6" v-if="control.disable6 == true">编辑</van-button>
                <van-button type="info" size="small" round @click="submit" v-if="control.disable6 == false">保存</van-button>
              </template>
            </van-cell>
            <van-tabs v-model="active" color="#4581F8" title-active-color="#4581F8">
              <van-tab :title-style="{'fontSize': '16px'}" v-for="(item, index) in dataForm.communityList" :key="index" :title="item.orgName">
                <van-cell title="所属网格" class="gridBtn" :value="item.grid.name || show.gridName" @click="control.disable6 ? (control.gridShow = false) : (control.gridShow = true)"
                          :value-class="{'value-common':show.gridName=='请选择'}" :is-link="!control.disable6">
                </van-cell>
                  <van-cell title="网格长" class="gridUser" v-if="item.grid.gridBoss">
                    <van-field clearable clear-trigger="always" readonly v-model="item.grid.gridBoss.name + ' ' + item.grid.gridBoss.mobile || '无'" placeholder="请输入" style="text-align: right">
                      <template #right-icon>
                        <img class="tel" :src="require('@/assets/img/mobile.png')" @click.stop="getUserMobile(item.grid.gridBoss.id)" />
                      </template>
                    </van-field>
                  </van-cell>
                <div v-for="(items, idx) in item.grid.gridManagerZz" :key="idx">
                  <van-cell title="专职网格员" class="gridUser">
                    <van-field clearable clear-trigger="always" readonly v-model="items.name + ' ' + items.mobile || '无'" placeholder="请输入" style="text-align: right">
                      <template #right-icon>
                        <img class="tel" :src="require('@/assets/img/mobile.png')" @click.stop="getUserMobile(item.id)" />
                      </template>
                    </van-field>
                  </van-cell>
                </div>

                <div v-for="(items, idx) in item.grid.gridManagerZdy" :key="idx">
                  <van-cell title="网格指导员" class="gridUser">
                    <van-field clearable clear-trigger="always" readonly v-model="items.name + ' ' + items.mobile || '无'" placeholder="请输入" style="text-align: right">
                      <template #right-icon>
                        <img class="tel" :src="require('@/assets/img/mobile.png')" @click.stop="getUserMobile(items.id)" />
                      </template>
                    </van-field>
                  </van-cell>
                </div>

                <div v-for="(items, idx) in item.grid.gridManager" :key="idx">
                  <van-cell title="网格员" class="gridUser">
                    <van-field clearable clear-trigger="always" readonly v-model="items.name + ' ' + items.mobile || '无'" placeholder="请输入" style="text-align: right">
                      <template #right-icon>
                        <img class="tel" :src="require('@/assets/img/mobile.png')" @click.stop="getUserMobile(items.id)" />
                      </template>
                    </van-field>
                  </van-cell>
                </div>
              </van-tab>
            </van-tabs>
          </van-cell-group>
        </template>
        <template v-if="changeType == 6">
          <van-cell-group>
            <van-cell class="custom">
              <template #title>
                <span class="custom-title">车辆信息</span>
              </template>
            </van-cell>
          </van-cell-group>

          <div class="list-info carManage">
            <div class="cont">
              <div class="list-item" v-for="item in dataForm.carList" :key="item.id">
                <div class="title">
                  <div class="title-left">
                    {{item.brand}}
                  </div>
                  <div class="title-right">
                    <van-button type="info" size="small" @click.stop="illPark(item.id, item.userId, item.subarea, item.subareaStr)">违停记录</van-button>
                  </div>
                </div>
                <div class="content" style="background-color: #fff;" @click="gotoCar(item)">
                  <div class="content-text">
                    <div :class="!item.isNewEnergy ? 'carText oilCar' : 'carText newEnergy'">{{item.carNumber}} <span>{{!item.isNewEnergy ? '油车' : '新能源'}}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="car-add">
            <img :src="require('@/assets/img/addCar.png')" alt="">
            <van-button type="info" size="small" @click.stop="gotoCar(null)">
              <img :src="require('@/assets/img/carIcon.png')" alt=""><span>添加车辆</span>
            </van-button>
          </div>
        </template>

        <template v-if="changeType == 7">
          <cause :userId="dataForm.id" :user-name="dataForm.name"/>
        </template>
        <template v-if="changeType == 8">
          <petition :userId="dataForm.id" :user-name="dataForm.name"/>
        </template>

        <template v-if="changeType == 9">
          <div class="info">
            <van-cell-group class="info-second">
              <van-cell class="info-title">
                <template #title>
                  <div class="blue-box"></div>
                  <span class="custom-title">用户政策信息</span>
                  <div style="color:#387FF5;float:right"
                       @click="control.policyVisible=!control.policyVisible">
                    <van-icon :name="control.policyVisible?'arrow-up':'arrow-down'"></van-icon>
                  </div>
                </template>
              </van-cell>
              <div v-for="(item, index) in medicalData.policyList" :key="index" v-if="control.policyVisible  && medicalData.policyList.length > 0">
                <van-field readonly :label="item.name" input-align="right"/>
              </div>
              <van-empty description="暂无用户政策信息" v-if="control.policyVisible && medicalData.policyList.length < 1"/>
            </van-cell-group>

            <van-cell-group class="info-second">
              <van-cell class="info-title">
                <template #title>
                  <div class="blue-box"></div>
                  <span class="custom-title">慢病史</span>
                  <div style="color:#387FF5;float:right"
                       @click="control.slowVisible=!control.slowVisible">
                    <van-icon :name="control.slowVisible?'arrow-up':'arrow-down'"></van-icon>
                  </div>
                </template>
              </van-cell>
              <div v-for="(item, index) in medicalData.chronicDiseaseList" :key="index" v-if="control.slowVisible && medicalData.chronicDiseaseList.length > 0">
                <van-field readonly :label="item.name" input-align="right"/>
              </div>
              <van-empty description="暂无慢病史信息" v-if="control.slowVisible && medicalData.chronicDiseaseList.length < 1"/>
            </van-cell-group>

            <van-cell-group class="info-second">
              <van-cell class="info-title">
                <template #title>
                  <div class="blue-box"></div>
                  <span class="custom-title">就诊信息</span>
                  <div style="color:#387FF5;float:right"
                       @click="control.diagnosisVisible=!control.diagnosisVisible">
                    <van-icon :name="control.diagnosisVisible?'arrow-up':'arrow-down'"></van-icon>
                  </div>
                </template>
              </van-cell>
              <div v-if="control.diagnosisVisible">
                <van-field
                    :readonly="true"
                    v-model="medicalData.card"
                    name="医保卡号"
                    label="医保卡号"
                    placeholder="未知"
                />
                <van-field
                    :readonly="true"
                    v-model="medicalData.hospitalVisits.visitDate"
                    name="就诊日期"
                    label="就诊日期"
                    placeholder="未知"
                />
                <van-cell name="诊断信息" title="诊断信息"
                          :value="dataForm.sex == 1 ? '男' : '女'"
                          readonly
                          placeholder="未知"
                />
                <van-field clearable input-align="left" clear-trigger="always"
                           v-model="medicalData.hospitalVisits.diagnosis" rows="4" class="left info-title"
                           :border="false" autosize type="textarea" placeholder="诊断信息" :readonly="true"/>
              </div>
            </van-cell-group>

            <van-cell-group class="info-second">
              <van-cell class="info-title">
                <template #title>
                  <div class="blue-box"></div>
                  <span class="custom-title">药品信息</span>
                  <div style="color:#387FF5;float:right"
                       @click="control.prescribeVisible=!control.prescribeVisible">
                    <van-icon :name="control.prescribeVisible ?'arrow-up':'arrow-down'"></van-icon>
                  </div>
                </template>
              </van-cell>
              <div v-for="(item, index) in medicalData.prescribeList" v-if="control.prescribeVisible && medicalData.prescribeList.length > 0">
                <van-cell title="药品名称" />
                <van-field clearable input-align="left" clear-trigger="always" v-model="item.medicine_name"
                           rows="1" class="left info-title" :border="false" autosize type="textarea" placeholder="药品名称"
                           :readonly="true"/>
                <van-cell title="数量">
                  <van-field
                      :readonly="true"
                      v-model="item.quantity"
                      name="数量"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="一次剂量">
                  <van-field
                      :readonly="true"
                      v-model="item.dosage"
                      name="一次剂量"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="一次剂量单位">
                  <van-field
                      :readonly="true"
                      v-model="item.unit"
                      name="一次剂量单位"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="用药天数">
                  <van-field
                      :readonly="true"
                      v-model="item.days"
                      name="用药天数"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="速配协定诊断名称">
                  <van-field
                      :readonly="true"
                      v-model="item.diagnosis"
                      name="速配协定诊断名称"
                      placeholder="未知"
                  />
                </van-cell>
                <van-divider :style="{ borderColor: '#ddd'}" />
              </div>
              <van-empty description="暂无药品信息" v-if="control.prescribeVisible && medicalData.prescribeList.length < 1"/>
            </van-cell-group>

            <van-cell-group class="info-second">
              <van-cell class="info-title">
                <template #title>
                  <div class="blue-box"></div>
                  <span class="custom-title">家属签约信息</span>
                  <div style="color:#387FF5;float:right"
                       @click="control.signVisible=!control.signVisible">
                    <van-icon :name="control.signVisible ?'arrow-up':'arrow-down'"></van-icon>
                  </div>
                </template>
              </van-cell>
              <div v-if="control.signVisible">
                <van-cell title="医生工号">
                  <van-field
                      :readonly="true"
                      v-model="medicalData.familyDoctor.doctorId"
                      name="医生工号"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="医生姓名">
                  <van-field
                      :readonly="true"
                      v-model="medicalData.familyDoctor.doctorName"
                      name="医生姓名"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="签约开始时间">
                  <van-field
                      :readonly="true"
                      v-model="medicalData.familyDoctor.startTime"
                      name="签约开始时间"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="签约结束时间">
                  <van-field
                      :readonly="true"
                      v-model="medicalData.familyDoctor.endTime"
                      name="签约结束时间"
                      placeholder="未知"
                  />
                </van-cell>
              </div>
            </van-cell-group>

            <van-cell-group class="info-second">
              <van-cell class="info-title">
                <template #title>
                  <div class="blue-box"></div>
                  <span class="custom-title">体检信息</span>
                  <div style="color:#387FF5;float:right"
                       @click="control.testVisible=!control.testVisible">
                    <van-icon :name="control.testVisible ?'arrow-up':'arrow-down'"></van-icon>
                  </div>
                </template>
              </van-cell>
              <div v-if="control.testVisible">
                <van-cell title="体检小结">
                  <van-field
                      :readonly="true"
                      v-model="medicalData.healthCheck.summary"
                      name="体检小结"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="体检诊断">
                  <van-field
                      :readonly="true"
                      v-model="medicalData.healthCheck.diagnosis"
                      name="体检诊断"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell title="体检时间">
                  <van-field
                      :readonly="true"
                      v-model="medicalData.healthCheck.checkDate"
                      name="体检时间"
                      placeholder="未知"
                  />
                </van-cell>
                <van-cell name="建议" title="建议"
                          readonly
                          placeholder="建议"
                />
                <van-field clearable input-align="left" clear-trigger="always" v-model="medicalData.healthCheck.suggest"
                           rows="4" class="left info-title" :border="false" autosize type="textarea" placeholder="建议"
                           :readonly="true"/>
              </div>
            </van-cell-group>

          </div>
        </template>

        <div class="form-btn-view" v-if="!dataForm.id && (active==0||active==1 || active==2) && changeType == 1">
          <van-row gutter="10" class="btns" v-if="control.disabled">
            <van-col span="12">
              <van-button plain type="danger" size="large" round @click="moveOut">迁出</van-button>
            </van-col>
            <van-col span="12">
              <van-button type="info" size="large" round @click="redact">编辑</van-button>
            </van-col>
          </van-row>
          <van-row gutter="10" class="btns" v-else>
            <van-col span="12">
              <van-button round block type="info" native-type="submit"
                          @click="submit(false)"
                          v-if="!control.disabled && !dataForm.id">确认添加
              </van-button>
              <van-button round block type="info"
                          @click="submit(false)"
                          v-if="!control.disabled && dataForm.id">确认保存
              </van-button>
            </van-col>
            <van-col span="12">
              <van-button round block type="info" native-type="submit"
                          @click="submit(true)"
                          v-if="!control.disabled && !dataForm.id">添加并返回
              </van-button>
              <van-button round block type="info"
                          @click="submit(true)"
                          v-if="!control.disabled && dataForm.id">保存并返回
              </van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
      <div class="check-info-wrap" v-if="!control.gridShow">
        <div class="check-title">选择信息</div>
        <div class="detail-info-wrap">
          <div class="info-item" :class="changeType === 1 ? 'checked' : ''" @click="changeCondition(1)">基本信息</div>
          <div class="info-item" :class="changeType === 2 ? 'checked' : ''" @click="changeCondition(2)">房屋信息</div>
          <div class="info-item" :class="changeType === 3 ? 'checked' : ''" @click="changeCondition(3)">家庭关系</div>
          <div class="info-item" :class="changeType === 4 ? 'checked' : ''" @click="changeCondition(4)">社会关系</div>
          <div class="info-item" v-if="dataForm.communityList[0] && dataForm.communityList[0].grid" :class="changeType === 5 ? 'checked' : ''" @click="changeCondition(5)">网格信息</div>
          <!--           v-if="dataForm.carList.length>0"-->
          <div class="info-item" :class="changeType === 6 ? 'checked' : ''" @click="changeCondition(6)">车辆信息</div>
          <div class="info-item" v-if="control.psychosis" :class="changeType === 7 ? 'checked' : ''" @click="changeCondition(7)">肇事信息</div>
          <div class="info-item" v-if="control.petition" :class="changeType === 8 ? 'checked' : ''" @click="changeCondition(8)">上访信息</div>
          <div class="info-item" v-if="control.oldShow == true" :class="changeType === 9 ? 'checked' : ''" @click="changeCondition(9)">用户政策信息</div>
        </div>
      </div>
    </div>
    <nation v-if="control.nationShow" @setNation="setNation"/>
    <nationality v-if="control.nationalityShow" @setNationality="setNationality"/>
    <grid-select v-show="control.gridShow" :list="selectList.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select>

  </div>

</template>

<script>
import {mapState} from "vuex";
import {getImageStream} from "@/utils/index";
import {formatterDate, formatterMonth} from "@/utils/utils";
import {getBirthdayFromIdCard} from "@/utils/utils";
import {getSexFromIdCard} from "@/utils/utils";
import {getNativePlaceByIdCard} from "@/utils/utils";
import {identityCodeValid,desensitizationIdNumber,desensitization} from "@/utils/utils";
import {listComRightLabel} from "@/utils/common"
import {getbelongSubArea, getBelongCommunity} from "@/utils/common"
import {upload, getRealMobile} from "@/utils/common"
import {getVirtualDict, getDictTree, getbelongGrid} from "@/utils/common"
import {isNumber} from "@/utils/validate"
import petition from "@/views/userRes/petition";
import cause from "@/views/userRes/cause";
import nation from "@/components/nation/nation";
import nationality from "@/components/nation/nationality";
import fieldView from '../../components/field-view/field-view.vue';
import GridSelect from '../userRes/grid-select.vue'
import cityData from "../../utils/data"
import relationship from "relationship.js"
import {getCity} from '@/utils'

var that
export default {
  name: "info",
  components: {
    nation,
    nationality,
    petition,
    cause,
    fieldView,
    GridSelect
  },
  data() {
    return {
      showdeath:false,
      isAdd: false,
      cityData: [],
      death: false,
      collapse: ['1'],
      houseIndex: 0,
      changeType: 1,
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      registryContent1: {
        '人户一致': '人户一致: 居民居住的房子和户籍地址相同。',
        '人在户不在': '人在户不在: 常住人口类型之一，没有在本社区落户，例如房东或业主买了本社区的房子，户口未迁过去。',
        '户在人不在': '户在人不在: 常住人口类型之一，例如房东或业主落户，但是未入住的。',
        '集体户(人户均在)': '人户均在本村社。',
        '集体户(户在人不在)': '户在本村社，人不在本村社居住。',
        '人户均不在': '人户均不在: 流动人口类型之一，例如投资客。',
        '本地租客': '本地租客: 本市户口的租客。',
        '其他人员': '其他人员: 组织关系挂靠，例如党员或退休关系挂靠。',
        '流动人口': '流动人口：市辖区以外户籍，在本村社居住的人口。'
      },
      showHouseTypeDes: false,
      minDate: new Date(2017, 1, 1),
      minDate1: new Date(1980, 1, 1),
      maxDate: new Date(2025, 1, 1),
      birthdayMin: new Date(1900,1),
      currentBirthDay: new Date(),
      dataForm: {
        cardNumber:'',
        cardOwnerName:'',
        cardName:'',
        id: "",
        headImg: "",
        name: "",
        usedName: '',
        orgId: "",
        sex: "",
        nationality: 59,
        idNumberType: "1",
        idNumber: "",
        mobile: "",
        fixedLine: '',
        nation: 2,    //2是汉族
        birthday: "",
        company: "",
        insuredStatus: '',
        regAddress: "",
        regAddressCode: '',
        regAddressCodeName: '',
        nowAddress: "",
        nowAddressCode: '',
        nowAddressCodeName: '',
        // registryType: "",
        death: "0",
        deathDate: "",
        deathCard: '',
        cremationDate: '',
        cremationCard: '',
        deathAddress: '',
        deathReason: '',
        emContact: "",
        emMobile: "",
        resRemark: "",
        temporaryHeadImg: "",
        temporaryHeadImgSuffix: "",
        shorts: [],//简称连接
        labels: [],
        hometown: '',
        community: '',
        marriage: '',
        spouseIdNumberType: '',
        education: '',
        jobType: '',
        specialty: '',
        health: '',
        militaryType: '',
        political: '',
        religious: '',
        unemploymentReason: '',
        unemploymentTime: '',
        retirementType: '',
        retirementReason: '',
        pension: '',
        employment: '',
        job: '',
        professional: '',
        amateur: '',
        demand: '',
        licensed: '',
        birthdayCode: '',
        spouseIdNumber: '',
        userInfoAuxiliaryId: '',
        live: '',
        carList: [],
        mobileCheck: 0,
        nowAddressCheck: 0,
        regAddressCheck: 0,
        // moveInDate: '',
        communityList: [],
        gridId: 0,
        miniGridId: 0,
        nowAddressPcc: [],
        regAddressPcc: []
      },
      userRes: '',
      medicalData: {
        card: '',
        familyDoctor: {
          doctorId: '',
          doctorName: '',
          startTime: '',
          endTime: ''
        },
        hospitalVisits: {
          visitDate: '',
          diagnosis: ''
        },
        healthCheck: {
          summary: '',
          diagnosis: '',
          checkDate: '',
          suggest: ''
        },
        prescribeList: [],
        chronicDiseaseList: [],
        policyList: [],
        result: ''
      },
      loading: false,
      active: 0,
      show: {
        idNumberTypeStr: "身份证",
        nationalityStr: "中国",
        nationStr: "汉族",
        headImg: require('@/assets/img/header.png'),//展示头像图
        registryTypeStr: "请选择",
        labelStr: '',
        Hometown: '请选择',
        community: '请选择',
        marriage: '请选择',
        spouseIdNumberType: '请选择',
        education: '请选择',
        political: '请选择',
        religious: '请选择',
        unemploymentTime: new Date(),
        employment: '请选择',
        live: '请选择',
        moveInDate: new Date(),
        healthStr: "请选择",
        militaryTypeStr: '请选择',
        insuredStatus: '请选择',
        retirementType: '请选择',
        pensionStr: '请选择',
        gridName: '请选择',
        jobType: '请选择',
        orgStr: '请选择',
        allCheck: null,
        birthdayStr: '请选择',
        regCodeStr: '请选择',
        nowCodeStr: '请选择',
        nowAddressPcc: '',
        regAddressPcc: ''
      },
      control: {
        disabled: false,
        idNumberType: false,
        birthday: false,
        deathDate: false,
        cremationDate: false,
        label: false,
        showNew: true,
        subareaPopup: false,
        houseTreeCascader: false,
        relationship: false,
        useType: false,
        psychosis: false,
        registryType: false,
        sex: false,
        live: false,
        community: false,
        marriage: false,
        healthShow: false,
        spouseIdNumberType: false,
        education: false,
        jobType: false,
        militaryTypeShow: false,
        religious: false,
        Hometown: false,
        political: false,
        retirementType: false,
        pensionShow: false,
        unemploymentTime: false,
        employment: false,
        insuredStatus: false,
        nationShow: false,
        nationalityShow: false,
        slowVisible: true,
        policyVisible: true,
        prescribeVisible: false,
        diagnosisVisible: false,
        signVisible: false,
        testVisible: false,
        oldShow: false,
        moveInDate: false,
        firstVisible: true,
        secondVisible: true,
        thirdVisible: false,
        fourthVisible: false,
        fiveVisible: false,
        sixVisible: false,
        sevenVisible: false,
        disable1: false,
        disable2: false,
        disable3: false,
        disable4: false,
        disable5: false,
        disable6: false,
        disable7: false,
        disable8: false,
        showPopover: false,
        gridShow: false,
        orgShow: false,
        regCodeShow: false,
        nowCodeShow: false,
        nowAddressPcc: false,
        regAddressPcc: false
      },
      selectList: {
        idNumberTypeList: [],
        houseTree: [],
        hometownList: [],
        communityList: [],
        spouseIdNumberTypeList: [],
        marriageList: [],
        educationList: [],
        jobTypeList: [],
        political: [],
        politicalList: [],
        religiousList: [],
        userCommunityLabelTree: [],
        healthList: [],
    //     [{id: 1, name: '健康或良好'}, {id: 2, name: '一般或较弱'}, {id: 3, name: '有慢性病'}, {id: 4, name: '残疾'},
    // {id: 10, name: '健康或良好'}, {id: 20, name: '一般或较弱'}, {id: 30, name: '有慢性病'}, {id: 40, name: '有慢性病'},
    // {id: 31, name: '心血管病'}, {id: 32, name: '脑血管病'}, {id: 33, name: '慢性呼吸系统病'}, {id: 34, name: '慢性消化系统病'},
    // {id: 35, name: '慢性肾炎'}, {id: 36, name: '结核病'}, {id: 37, name: '糖尿病'}, {id: 38, name: '神经或精神疾病'}, {
    //   id: 41,
    //       name: '癌症'
    // },
    // {id: 49, name: '其他慢性病'}, {id: 60, name: '残疾'}, {id: 61, name: '视力残疾'}, {id: 62, name: '听力残疾'}, {
    //   id: 63,
    //       name: '言语残疾'
    // },
    // {id: 64, name: '肢体残疾'}, {id: 65, name: '智力残疾'}, {id: 66, name: '精神残疾'}, {id: 67, name: '多重残疾'}, {
    //   id: 69,
    //       name: '其他残疾'
    // }],
        militaryTypeList: [],
        insuredStatusList: [],
        retirementTypeList: [],
        actions: [{text: '该房屋所在网格为当前居民默认管理网格', className: 'gridPopover'}],
        familyList: [],
        socialList: [],
        gridList: [],
        orgList: [],
        regCodeList: [],
        nowCodeList: []
      },
      temporaryLabel: [],//临时标签
      labels: [],
      resHouses: [],
      checkedHouses: [],//目前选中的房屋
      orgDepth: 0,
      codeType: null,
      regCodeId: null,
      nowCodeId: null,
      isTipShow: false,
      tipValue: '',
      isNowAddressTipShow: false,
      nowAddressTipValue: '',
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
    }
  },
  watch: {
    'dataForm.idNumber'(value, oldVal) {
      if (that.dataForm.idNumberType == 1) {
        if (value.length == 18 || value.length == 15) {
          // that.dataForm.birthday = getBirthdayFromIdCard(value);
          // that.dataForm.sex = getSexFromIdCard(value);
        }
      }
    },
    'dataForm.death'(value) {
      this.control.disabled=this.dataForm.death == 1 ? false : true
      this.death = this.dataForm.death == 1 ? true : false
      this.control.disable5=this.dataForm.death == 1 ? false : true
      this.showdeath = this.dataForm.death == 1 ? true : false
    },
    death() {
      this.dataForm.death = this.death ? 1 : 0
    },
    'dataForm.mobileInput': {
      handler(val, old) {
        this.dataForm.mobileCheck = 0
      }
    },
    'dataForm.nowAddress': {
      handler(val, old) {
        this.dataForm.nowAddressCheck = 0
      }
    },
    'dataForm.regAddress': {
      handler(val, old) {
        this.dataForm.regAddressCheck = 0
      }
    }
  },
  computed: {...mapState(['nationality', 'nation'])},
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.dataForm.unemploymentTime = formatterDate(new Date())
    this.orgDepth = this.$globalData.userInfo.depth
    this.codeType = this.$globalData.userInfo.codeType
    this.changeType = 1
    if (sessionStorage.getItem('changeType')) {
      this.changeType = parseInt(sessionStorage.getItem('changeType'))
    }
    that = this
    // 扫码跳转吧
    let options = this.$route.query


    if (options.houseFlag){
      this.control.showNew = false
      options.name = ''
      options.houseId = options.id
    }
    if (sessionStorage.getItem('showInfo')) {
      this.dataForm.id = sessionStorage.getItem('showInfo')
      sessionStorage.setItem('showInfo', '')
    } else {
      this.dataForm.id = parseInt(options.userId) || ''
    }
    if (!this.dataForm.id) {
      this.isAdd = true
    }
    this.dataForm.name = options.name || ""
    this.dataForm.idNumberType = 1 || ""
    this.show.idNumberTypeStr = "身份证" || ""
    this.dataForm.idNumber = options.idNumber || ""
    this.dataForm.regAddress = options.regAddress || ""
    this.dataForm.sex = options.sex == "男" ? 1 : 2 || ""
    this.dataForm.nation = options.nation || "2"
    this.show.nationStr = options.nationStr || "汉族"
    this.userName = this.$globalData.userInfo.userName
    this.mobile = this.$globalData.userInfo.mobile


    if (options.houseId) {
      this.resHouses = [{
        id: 0,
        subarea: options.subarea,
        houseId: options.houseId,
        relationship: 0,
        useType: 0,
        nowLive: 1,
        remark: '',
        houses: [],
        moveOut: 0,
        registryTypeStr: "未知",
        subareaName: options.subareaName,
        fullName: options.fullName,
        relationshipStr: "请选择",
        useTypeStr: "请选择",
        status: 0,
        live: ''
      }]
      // this.$set(this.resHouses,this.resHouses)
      // this.houseChange(that.data.resHouses)
    }
    this.init()
    // 新房屋下居民点修改过来
    if(this.$route.query.editor && JSON.parse(this.$route.query.editor)) {
      this.control.disabled = false
    }
  },
  methods: {
    nowAddressPccOverlay() {

    },
    nowAddressPccClose(e) {
      this.control.nowAddressPcc = false
    },
    nowAddressPccFinish(e) {
      this.dataForm.nowAddressPcc = e.selectedOptions.map(item => item.name)
      this.show.nowAddressPcc = e.selectedOptions.map(item => item.name).join('/')
    },
    regAddressPccPccOverlay() {

    },
    regAddressPccPccClose(e) {
      this.control.regAddressPcc = false
    },
    regAddressPccFinish(e) {
      this.dataForm.regAddressPcc = e.selectedOptions.map(item => item.name)
      this.show.regAddressPcc = e.selectedOptions.map(item => item.name).join('/')
    },
    down(event) {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move(event) {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove",function (event) {
          event.preventDefault();
        },{passive:false});
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    getUserMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel://${data.mobile}`
      })
    },
    changeCondition(type) {
      this.changeType = type
    },
    disableChange(val) {
      this.disable5 = val
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    inspect (name, value) {
      if (this.control.disabled || this.dataForm[name] == 1) {
        return
      }
      this.$dialog.confirm({
        title: '',
        center: true,
        message: `<p>您修改的<span style="color: #409eff">${value}</span>确认已核查过吗?</p>`,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(action => {
        this.dataForm[name] = 1
      })
    },
    setNation(value) {
      console.log(value)
      if (value) {
        this.dataForm.nation = value.value
        this.show.nationStr = value.label
      }
      this.control.nationShow = false
    },
    setNationality(value) {
      this.dataForm.nationality = value.value
      this.show.nationalityStr = value.label
      this.control.nationalityShow = false
    },
    getCode(e,type) {
      this.isTipShow = false
      this.tipValue = ''
      this.isNowAddressTipShow = false
      this.nowAddressTipValue = ''
      let url = '/wxapp/sys/addressCodeMapping/listByName'
      let val = (type == 1 ? this.dataForm.regAddressCodeName : this.dataForm.nowAddressCodeName)
      let name = true
      if (isNumber(val)) {
        url = '/wxapp/sys/addressCodeMapping/infoByCode'
        name = false
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        params: this.$http.adornParams({
          name: name ? val : undefined,
          code: name ? undefined : val,
          type: 2
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = (data.list || data.sysAddressCodeMapping).map(item => {
            return {
              value: item.houseCode ? item.houseCode : item.doorCode,
              label: item.fullName,
              codeMappingId: item.id
            }
          })
          if (type == 1) {
            this.control.regCodeShow = true
            this.selectList.regCodeList = list
          } else {
            this.control.nowCodeShow = true
            this.selectList.nowCodeList = list
          }
        } else {
          if (type == 1) {
            this.isTipShow = true
            this.tipValue = data.msg
          } else {
            this.isNowAddressTipShow = true
            this.nowAddressTipValue = data.msg
          }
          // this.$toast.fail(data.msg)
        }
      })
    },
    codeConfirm (value, type){
      if (type == 1) {
        if (value) {
          this.show.regCodeStr = value.value;
          this.dataForm.regAddressCode = value.value;
          this.dataForm.regAddressCodeName = value.label;
          this.dataForm.regAddress = value.label
          this.selectList.regCodeList.map(item => {
            if (item.value == value.value) {
              this.regCodeId = item.codeMappingId
            }
          })
        }
        this.control.regCodeShow = false;
      } else {
        if (value) {
          this.show.nowCodeStr = value.value;
          this.dataForm.nowAddressCode = value.value;
          this.dataForm.nowAddressCodeName = value.label;
          this.dataForm.nowAddress = value.label
          this.selectList.nowCodeList.map(item => {
            if (item.value == value.value) {
              this.nowCodeId = item.codeMappingId
            }
          })
        }
        this.control.nowCodeShow = false;
      }
    },
    init() {
      this.selectInfo()
      console.log(this.$globalData.result)
      if (this.$globalData.result && this.$globalData.userInfo.county === '12345') {
        let code = this.$globalData.result
        this.control.oldShow = true
        this.control.disabled = true
        this.active = 3
        this.getMedicalUser(code)
      }else if (this.$globalData.result) {
        this.dataForm.id = this.$globalData.result
      }
      if (this.dataForm.id) {
        (this.control.disable1 = true, this.control.disable2 = true, this.control.disable3 = true,
            this.control.disable4 = true, this.control.disable5 = true, this.control.disable6 = true,this.control.disable7 = true, this.control.disable8 = true);
        this.control.disabled = true
        this.dataInfo()
      } else {
        that.dataForm.community = this.$globalData.userInfo.orgId
        this.dataForm.orgId = this.$globalData.userInfo.orgId
        this.$nextTick(() => {
          // this.$refs.fieldView.init(null, 1, this.$globalData.userInfo.orgId)
        })
        //新建的，获取中国
      }
    },
    sixChange() {
      this.control.sixVisible = !this.control.sixVisible
      if (this.control.sixVisible) {
        this.$nextTick(() => {
          this.$refs.fieldView.init(this.dataForm.id, 1, this.$globalData.userInfo.orgId)
        })
      }
    },
    async submit(isReturn) {
      console.log(this.dataForm.mobileCheck)
      let labels = this.dataForm.labels.join(',')
      let nativePlace = this.dataForm.hometown

      let numberBool = identityCodeValid(that.dataForm.idNumber)
      if (numberBool == '身份证号格式错误' && that.dataForm.idNumberType == 1 && that.dataForm.idNumber.indexOf('*') == -1) {
        return that.$toast.fail(numberBool)
      }
      if (this.dataForm.name.trim() == '') {
        return this.$toast.fail('请输入姓名')
      }
      if (this.dataForm.idNumber.trim() == '' && that.show.idNumberTypeStr != '无证件') {
        return this.$toast.fail('请输入有效证件号')
      }
      if (this.dataForm.mobile.trim() == '' && this.dataForm.death != 1) {
        return this.$toast.fail('请输入手机号码')
      }
      if (this.show.registryTypeStr == '' || this.show.registryTypeStr == '请选择') {
        return this.$toast.fail('请选择户籍类型')
      }
      if (((!this.dataForm.id  || (this.dataForm.id && !this.userRes))) && this.orgDepth == 4 && this.show.orgStr=='请选择') {
        return this.$toast.fail('请选择所属社区')
      }
      let extendInfoList = null
      // 自定义字段参数
      if (this.$refs.fieldView) {
        extendInfoList=   await this.$refs.fieldView.submitForm()
      }

      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '提交中...',
      })
      let employmentDemand = this.dataForm.demand
      let licensedinformation = this.dataForm.licensed
      let isWish = this.dataForm.employment
      let id = this.dataForm.userInfoAuxiliaryId
      this.$http({
        url: this.$http.adornUrl(`/wxapp/app/userinfoauxiliary/save`),
        method: 'post',
        data: this.$http.adornData({
          amateur: this.dataForm.amateur,
          employmentDemand: employmentDemand,
          id: id,
          isWish: isWish,
          licensedinformation: licensedinformation,
          professional: this.dataForm.professional,
          registerTime: this.dataForm.registerTime,
          userId: this.dataForm.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/save`),
            method: 'post',
            data: this.$http.adornData({...this.dataForm, labels, nativePlace, extendInfoList,
              nowAddressProvince: this.dataForm.nowAddressPcc[0] || '',
              nowAddressCity: this.dataForm.nowAddressPcc[1] || '',
              nowAddressCounty: this.dataForm.nowAddressPcc[2] || '',
              regAddressProvince: this.dataForm.regAddressPcc[0] || '',
              regAddressCity: this.dataForm.regAddressPcc[1] || '',
              regAddressCounty: this.dataForm.regAddressPcc[2] || ''
            })
          }).then(({data}) => {
            if (data.code == 0) {
              this.control.disabled = true
              this.dataForm.id = data.userId
              this.control.disable1 = true
              this.control.disable2 = true
              this.control.disable3 = true
              this.control.disable4 = true
              this.control.disable5 = true
              this.control.disable6 = true
              this.control.disable7 = true
              this.control.disable8 = true
              if (this.resHouses.length != 0) {
                this.resHouses.forEach(item => {
                  if (!item.userId) {
                    item.userId = this.dataForm.id
                  }
                  if (item.live == '否') {
                    item.nowLive = 0
                  } else {
                    item.nowLive = 1
                  }
                })
                console.log(this.resHouses)
                this.$http({
                  url: this.$http.adornUrl(`/wxapp/user/res/houseRes/saveOrUpdate`),
                  method: 'post',
                  data: this.$http.adornData({resHouses: JSON.stringify(this.resHouses)})
                }).then(({data}) => {
                  this.$toast.clear()
                  if (data.code == 0) {
                    this.$toast.success({
                      message: '成功',
                      duration: 1500,
                      onClose: () => {
                        if (isReturn) {
                          this.$router.go(-1)
                        }
                      }
                    })
                  }
                })
              } else {
                this.$toast.clear()
                this.$toast.success({
                  message: '成功',
                  duration: 1500,
                  onClose: () => {
                    this.$dialog.confirm({
                      message: '基本信息保存成功，是否继续绑定房屋'
                    }).then(() => {
                      this.changeType = 2
                    }).catch(() => {
                      // this.$router.go(-1)
                    })
                  }
                })
                // this.active = 2
                // this.$toast.fail({
                //   message: '请添加房屋',
                //   duration: 1500
                // })
              }
            }
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    dataInfo() {
      this.getInfoById();
      this.resHousesInfo();
      this.getUserinfoauxiliary()
      this.getCarList();
      this.getSocialList()
    },
    getFamilyList() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/res/family/list`),
        method: 'post',
        params: this.$http.adornParams({
          userId: this.dataForm.id,
          community: this.dataForm.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          data.family.map(item => {
            if (!item.relationAB) {
              if (!item.relationBA) {
                if (item.relationA) {
                  let relation = relationship({text: item.relationA, reverse: true, sex: item.sexHousehold == 1 ? 1 : 0})
                  if (item.relationB == '户主') {
                    item.relationAB = relation[0] || '其他'
                  } else {
                    let relationStr = relationship({text: relation[0] + '的' + item.relationB, reverse: false, sex: item.sexA == 1 ? 1 : 0})
                    if (relationStr.length) {
                      item.relationAB = relationStr[0]
                    } else {
                      item.relationAB = '其他'
                    }
                  }
                }
              } else {
                let relationStr = relationship({text: item.relationBA, reverse: true, sex: item.sexHousehold == 1 ? 1 : 0})
                if (relationStr.length) {
                  item.relationAB = relationStr[0]
                } else {
                  item.relationAB = '其他'
                }
              }
            }
          })
          that.selectList.familyList = data.family
        }
      })
    },
    getSocialList() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/socialRelations/listByUser`),
        method: 'post',
        params: this.$http.adornParams({
          userId: this.dataForm.id,
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.selectList.socialList = data.list;
        }
      })
    },
    getMedicalUser(code) {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/getByCode`),
        method: 'post',
        params: this.$http.adornParams({
          code: code
        })
      }).then(({data}) => {
        if (data.code == 0) {
          let result = data.result.id_card
          that.medicalData.card = data.result.medical_card_no
          if (data.result.family_doctor !== null ) {
            that.medicalData.familyDoctor.doctorId = data.result.family_doctor.doctor_id
            that.medicalData.familyDoctor.doctorName = data.result.family_doctor.doctor_name
            let arr = data.result.family_doctor.sign_start_date.split(' ')[0].split('/')
            if (arr[1] <= 9) {
              arr[1] = '0' + arr[1]
            }
            if (arr[0] <= 9) {
              arr[0] = '0' + arr[0]
            }
            let startDate = arr[2] + '-' + arr[1] + '-' + arr[0]
            that.medicalData.familyDoctor.startTime = startDate

            let arr2 = data.result.family_doctor.sign_end_date.split(' ')[0].split('/')
            if (arr2[1] <= 9) {
              arr2[1] = '0' + arr2[1]
            }
            if (arr2[0] <= 9) {
              arr2[0] = '0' + arr2[0]
            }
            let endDate = arr2[2] + '-' + arr2[1] + '-' + arr2[0]
            that.medicalData.familyDoctor.endTime = endDate
          }
          that.medicalData.hospitalVisits.visitDate = data.result.hospital_visits !== null ? data.result.hospital_visits.visit_date : ''
          that.medicalData.hospitalVisits.diagnosis = data.result.hospital_visits !== null ? data.result.hospital_visits.diagnosis : ''
          that.medicalData.healthCheck.summary = data.result.health_check !== null ? data.result.healthCheck.summary : ''
          that.medicalData.healthCheck.diagnosis = data.result.health_check !== null ? data.result.healthCheck.diagnosis : ''
          that.medicalData.healthCheck.checkDate = data.result.health_check !== null ? data.result.healthCheck.check_date : ''
          that.medicalData.healthCheck.suggest = data.result.health_check !== null ? data.result.healthCheck.suggest : ''
          that.medicalData.chronicDiseaseList = data.result.chronic_disease_list !== null ? data.result.chronic_disease_list : ''
          that.medicalData.policyList = data.result.policy_list !== null ? data.result.policy_list : ''
          if (data.result.hospital_visits!= null && data.result.hospital_visits.prescribe_list !== null) {
            that.medicalData.prescribeList = data.result.hospital_visits.prescribe_list
          }
          this.getMedicalUserInfo(result)
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    getMedicalCare() {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/getHealthRecord`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: that.dataForm.idNumber
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.medicalData.card = data.result.medical_card_no
          if (data.result.family_doctor !== null ) {
            that.medicalData.familyDoctor.doctorId = data.result.family_doctor.doctor_id
            that.medicalData.familyDoctor.doctorName = data.result.family_doctor.doctor_name
            let arr = data.result.family_doctor.sign_start_date.split(' ')[0].split('/')
            if (arr[1] <= 9) {
              arr[1] = '0' + arr[1]
            }
            if (arr[0] <= 9) {
              arr[0] = '0' + arr[0]
            }
            let startDate = arr[2] + '-' + arr[1] + '-' + arr[0]
            that.medicalData.familyDoctor.startTime = startDate

            let arr2 = data.result.family_doctor.sign_end_date.split(' ')[0].split('/')
            if (arr2[1] <= 9) {
              arr2[1] = '0' + arr2[1]
            }
            if (arr2[0] <= 9) {
              arr2[0] = '0' + arr2[0]
            }
            let endDate = arr2[2] + '-' + arr2[1] + '-' + arr2[0]
            that.medicalData.familyDoctor.endTime = endDate
          }
          that.medicalData.hospitalVisits.visitDate = data.result.hospital_visits !== null ? data.result.hospital_visits.visit_date : ''
          that.medicalData.hospitalVisits.diagnosis = data.result.hospital_visits !== null ? data.result.hospital_visits.diagnosis : ''
          that.medicalData.prescribeList = data.result.hospital_visits !== null ? data.result.hospital_visits.prescribe_list : ''
          that.medicalData.healthCheck.summary = data.result.health_check !== null ? data.result.healthCheck.summary : ''
          that.medicalData.healthCheck.diagnosis = data.result.health_check !== null ? data.result.healthCheck.diagnosis : ''
          that.medicalData.healthCheck.checkDate = data.result.health_check !== null ? data.result.healthCheck.check_date : ''
          that.medicalData.healthCheck.suggest = data.result.health_check !== null ? data.result.healthCheck.suggest : ''
          that.medicalData.chronicDiseaseList = data.result.chronic_disease_list !== null ? data.result.chronic_disease_list : ''
          that.medicalData.policyList = data.result.policy_list !== null ? data.result.policy_list : ''
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    getMedicalUserInfo(result){
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/userInfo`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: result
        })
      }).then(({data}) => {
        if (data.code == 0) {
          // 用户基本信息
          that.dataForm.id = data.userInfo.id || 0
          that.dataForm.name = data.userInfo.name || ""
          that.dataForm.mobile = data.userInfo.mobile || ""
          that.dataForm.fixedLine = data.userInfo.fixedLine || ""
          that.dataForm.sex = data.userInfo.sex || ""
          that.dataForm.nationality = data.userInfo.nationality || ""
          that.dataForm.nation = data.userInfo.nation || ""
          that.dataForm.idNumber = data.userInfo.idNumber || ""
          that.dataForm.birthday = data.userInfo.birthday || ""
          that.dataForm.regAddress = data.userInfo.regAddress || ""
          that.dataForm.nowAddress = data.userInfo.nowAddress || ""
          that.dataForm.headImg = data.userInfo.headImg || ""
          that.dataForm.company = data.userInfo.company || ""
          that.dataForm.death = data.userInfo.death || "0"
          that.dataForm.deathDate = data.userInfo.deathDate || ""
          that.show.nationalityStr = data.userInfo.nationalityStr || "未知"
          that.show.nationStr = data.userInfo.nationStr || "未知"
          that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
          that.dataForm.education = data.userInfo.education || ""
          that.dataForm.spouseIdNumber = data.userInfo.spouseIdNumber || ""
          that.dataForm.political = data.userInfo.political || ""
          that.dataForm.usedName = data.userInfo.usedName || ""
          that.dataForm.hometown = data.userInfo.nativePlace || ""
          that.dataForm.religious = data.userInfo.religious || ""
          that.dataForm.marriage = data.userInfo.marriage || ""
          that.dataForm.spouseIdNumberType = data.userInfo.spouseIdNumberType || ""
          that.show.spouseIdNumberType = data.userInfo.spouseIdNumberTypeStr || ""
          that.show.marriage = data.userInfo.marriageStr || '请选择'
          that.show.religious = data.userInfo.religiousStr || '请选择'
          that.show.education = data.userInfo.educationStr || '请选择'
          that.show.political = data.userInfo.politicalStr || '请选择'
          that.show.spouseIdNumber = data.userInfo.spouseIdNumberStr || '请选择'
          if (null != data.userInfo.headImg) {
            that.show.headImg = data.userInfo.headImg ? this.getImageStream(data.userInfo.headImg) : this.getImageStream("files/wx/images/content/headImg.png")
          }
          // 居民信息
          if (data.userRes !== null) {
            // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
            //   data.userRes.street, data.userRes.community]
            that.dataForm.registryType = data.userRes.registryType + '' || ""
            that.dataForm.emMobile = data.userRes.emMobile || ""
            that.dataForm.emContact = data.userRes.emContact || ""
            that.dataForm.resRemark = data.userRes.remark || ""
            that.dataForm.orgId = data.userRes.community || ""

            that.show.registryTypeStr = data.userRes.registryTypeStr || "未知"
          }
          console.log(that.dataForm)
          //debugger
          // 居民标签
          var resLabels = data.resLabels;
          var labels = []
          if (resLabels != null) {
            let shorts = that.dataForm.shorts
            for (let i in resLabels) {
              let resLabel = resLabels[i]
              if (resLabel.shortName) {
                shorts.push(resLabel.shortName)
              }
            }
            console.log(resLabels)
            labels = that.formatLabel(resLabels, true);
            console.log(labels)

            let tabs = [
              {
                key: 'userResInfo',
                title: '居民信息',
                content: 'Content of tab 1',
              },
              {
                key: 'houseRes',
                title: '房屋信息',
                content: 'Content of tab 2',
              }]

            if (shorts.indexOf("精") > -1) {
              that.control.psychosis = true
            }

            if (shorts.indexOf("访") > -1) {
              that.control.petition = true
            }
          }
          that.labels = labels

          let idsArray = labels.map((item) => {
            item["delete"] = false
            return item.ids
          })
          that.dataForm.labels = that.dataForm.labels.concat(idsArray)
          this.resHousesInfo()
          this.getUserinfoauxiliary()
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    getUserinfoauxiliary() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/app/userinfoauxiliary/info/${this.dataForm.id}`),
        method: 'post',
      }).then(({data}) => {
        if (data.code == 0) {
          if (data.userInfoAuxiliary != null){
            that.dataForm.professional = data.userInfoAuxiliary.professional || ''
            that.dataForm.amateur = data.userInfoAuxiliary.amateur || ''
            that.dataForm.demand = data.userInfoAuxiliary.employmentDemand || ''
            that.dataForm.licensed = data.userInfoAuxiliary.licensedinformation || ''
            that.show.employment = data.userInfoAuxiliary.isWish == 1 ? '有' : '无'
            that.dataForm.employment = data.userInfoAuxiliary.isWish || ''
            that.dataForm.registerTime = data.userInfoAuxiliary.registerTime || new Date()
            that.dataForm.userInfoAuxiliaryId = data.userInfoAuxiliary.id || 0
          }
        }
      })
    },
    getCarList() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/devicecar/list`),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: that.$globalData.userInfo.orgId,
          userId: this.dataForm.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.dataForm.carList = data.page.list;
        }
      })
    },
    getInfoById () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/info`),
        method: 'post',
        params: this.$http.adornParams({
          id: parseInt(that.dataForm.id),
          orgId: that.$globalData.userInfo.orgId,
          userType: 1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataFormInfo(data)
          this.getFamilyList()
        }
      })
    },
    getInfoByIdNumber () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/getByIdNumber`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: that.dataForm.idNumber,
          orgId: that.$globalData.userInfo.orgId,
          userType: 1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataFormInfo(data)
        } else {
          if (this.dataForm.idNumberType == 1) {
            this.show.birthdayStr = getBirthdayFromIdCard(this.dataForm.idNumber)
            this.dataForm.birthday = getBirthdayFromIdCard(this.dataForm.idNumber)
            this.dataForm.sex = getSexFromIdCard(this.dataForm.idNumber)
          }
        }
      })
    },
    dataFormInfo(data) {
      if (data.code == 0) {
        // 用户基本信息
        that.dataForm.id = data.userInfo.id || 0
        that.dataForm.name = data.userInfo.name || ""
        that.dataForm.mobile = data.userInfo.mobile || ""
        // that.dataForm.mobileInput = data.userInfo.mobile || ""
        that.dataForm.fixedLine = data.userInfo.fixedLine || ""
        that.dataForm.sex = data.userInfo.sex || ""
        that.dataForm.nationality = data.userInfo.nationality || ""
        that.dataForm.nation = data.userInfo.nation || ""
        that.dataForm.idNumber = data.userInfo.idNumber || ""
        that.dataForm.birthday = data.userInfo.birthday
        that.dataForm.nowAddressPcc = [data.userInfo.nowAddressProvince, data.userInfo.nowAddressCity, data.userInfo.nowAddressCounty].filter(i => i)
        that.dataForm.regAddressPcc = [data.userInfo.regAddressProvince, data.userInfo.regAddressCity, data.userInfo.regAddressCounty].filter(i => i)
        that.show.nowAddressPcc = that.dataForm.nowAddressPcc.join('/')
        that.show.regAddressPcc = that.dataForm.regAddressPcc.join('/')
        if (data.userInfo.birthday) {
          that.show.birthdayStr = data.userInfo.birthday
          that.currentBirthDay = new Date(data.userInfo.birthday)
        }
        that.dataForm.cardName=data.userAuxiliary ? data.userAuxiliary.cardName : ''
        that.dataForm.cardOwnerName=data.userAuxiliary ? data.userAuxiliary.cardOwnerName : ''
        that.dataForm.cardNumber=data.userAuxiliary ? data.userAuxiliary.cardNumber : ''
        that.dataForm.regAddress = data.userInfo.regAddress || ""
        that.dataForm.nowAddress = data.userInfo.nowAddress || ""
        that.dataForm.regAddressCode = data.userInfo.regAddressCode
        that.dataForm.regAddressCodeName = data.userInfo.regAddressCodeName
        that.show.regCodeStr = data.userInfo.regAddressCode
        that.dataForm.nowAddressCode = data.userInfo.nowAddressCode
        that.dataForm.nowAddressCodeName = data.userInfo.nowAddressCodeName
        if (data.userRes.registryType == 1 && !this.dataForm.regAddress) this.dataForm.regAddress = data.userInfo.regAddressCodeName
        if (data.userRes.registryType == 1 && !this.dataForm.nowAddress) this.dataForm.nowAddress = data.userInfo.nowAddressCodeName
        that.show.nowCodeStr = data.userInfo.nowAddressCode
        that.dataForm.headImg = data.userInfo.headImg || ""
        that.dataForm.company = data.userInfo.company || ""
        that.dataForm.death = data.userInfo.death || "0"
        that.dataForm.deathDate = data.userInfo.deathDate || ""
        that.show.nationalityStr = data.userInfo.nationalityStr || "未知"
        that.show.nationStr = data.userInfo.nationStr || "未知"
        that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
        that.dataForm.idNumberType = data.userInfo.idNumberType || "1"
        that.dataForm.education = data.userInfo.education || ""
        that.dataForm.spouseIdNumber = data.userInfo.spouseIdNumber || ""
        that.dataForm.political = data.userInfo.political || ""
        that.dataForm.usedName = data.userInfo.usedName || ""
        that.dataForm.hometown = data.userInfo.nativePlace || ""
        that.dataForm.religious = data.userInfo.religious || ""
        that.dataForm.marriage = data.userInfo.marriage || ""
        that.dataForm.spouseIdNumberType = data.userInfo.spouseIdNumberType || ""
        that.dataForm.birthdayCode = data.userInfo.birthdayCode || ''
        that.dataForm.health = data.userInfo.health || ''
        that.show.healthStr = data.userInfo.healthStr || '请选择'
        that.dataForm.cremationDate = data.userInfo.cremationDate
        this.$nextTick(() => {
          that.dataForm.mobileCheck = data.userInfo.mobileCheck || 0
          that.dataForm.nowAddressCheck = data.userInfo.nowAddressCheck || 0
          that.dataForm.regAddressCheck = data.userInfo.regAddressCheck || 0
        })
        that.show.spouseIdNumberType = data.userInfo.spouseIdNumberTypeStr || ""
        that.show.marriage = data.userInfo.marriageStr || '请选择'
        that.show.religious = data.userInfo.religiousStr || '请选择'
        that.show.education = data.userInfo.educationStr || '请选择'
        that.show.political = data.userInfo.politicalStr || '请选择'
        that.show.spouseIdNumber = data.userInfo.spouseIdNumberStr || '请选择'
        if (null != data.userInfo.headImg) {
          that.show.headImg = data.userInfo.headImg ? this.getImageStream(data.userInfo.headImg) : this.getImageStream("files/wx/images/content/headImg.png")
        }
        // 居民信息
        if (data.userRes !== null && data.userRes !== undefined) {
          // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
          //   data.userRes.street, data.userRes.community]
          this.userRes = data.userRes
          that.dataForm.registryType = data.userRes.registryType + '' || ""
          that.dataForm.emMobile = data.userRes.emMobile || ""
          that.dataForm.emContact = data.userRes.emContact || ""
          that.dataForm.resRemark = data.userRes.remark || ""
          that.dataForm.orgId = data.userRes.community || ""

          that.show.registryTypeStr = data.userRes.registryTypeStr || "未知"
          that.dataForm.moveInDate = data.userRes.moveInDate || ''
          that.show.allCheck = data.userRes.allCheck
        }else {
          this.userRes = null
        }
        if (data.userAuxiliary !== null && data.userAuxiliary !== undefined) {
          that.dataForm.job = data.userAuxiliary.job
          that.dataForm.jobType = data.userAuxiliary.jobType
          that.show.jobType = data.userAuxiliary.jobTypeStr
          that.dataForm.insuredStatus = data.userAuxiliary.insuredStatus
          that.show.insuredStatus = data.userAuxiliary.insuredStatusStr
          that.dataForm.specialty = data.userAuxiliary.specialty || ''
          that.dataForm.militaryType = data.userAuxiliary.militaryType || ''
          that.show.militaryTypeStr = data.userAuxiliary.militaryTypeStr || '请选择'
          that.dataForm.unemploymentReason = data.userAuxiliary.unemploymentReason
          that.dataForm.retirementType = data.userAuxiliary.retirementType
          that.show.retirementType = data.userAuxiliary.retirementTypeStr || '请选择'
          that.dataForm.retirementReason = data.userAuxiliary.retirementReason
          that.dataForm.pension = data.userAuxiliary.pension
          that.show.pensionStr = data.userAuxiliary.pensionStr || '请选择'
          that.dataForm.deathCard = data.userAuxiliary.deathCard
          that.dataForm.cremationCard = data.userAuxiliary.cremationCard
          that.dataForm.deathAddress = data.userAuxiliary.deathAddress
          that.dataForm.deathReason = data.userAuxiliary.deathReason
        }
        console.log(that.dataForm)
        // 居民标签
        var resLabels = data.resLabels;
        var labels = []
        if (resLabels != null && resLabels != undefined) {
          let shorts = that.dataForm.shorts
          for (let i in resLabels) {
            let resLabel = resLabels[i]
            if (resLabel.shortName) {
              shorts.push(resLabel.shortName)
            }
          }
          //目前只有钱塘区需要智养功能
          if (this.$globalData.userInfo.county == 12345) {
            if (resLabels !== null && resLabels.length > 0) {
              resLabels.map(item => {
                if (item.path.indexOf(",2,") !== -1) {
                  this.control.oldShow = true
                  this.getMedicalCare()
                }
              })
            }
          }
          labels = that.formatLabel(resLabels, true);

          let tabs = [
            {
              key: 'userResInfo',
              title: '居民信息',
              content: 'Content of tab 1',
            },
            {
              key: 'houseRes',
              title: '房屋信息',
              content: 'Content of tab 2',
            }]

          if (shorts.indexOf("精") > -1) {
            that.control.psychosis = true
          }

          if (shorts.indexOf("访") > -1) {
            that.control.petition = true
          }
        }
        that.labels = labels

        let idsArray = labels.map((item) => {
          item["delete"] = false
          return item.ids
        })
        that.dataForm.labels = that.dataForm.labels.concat(idsArray)
        if(data.userResList && data.userResList.length > 0){
          that.dataForm.communityList = data.userResList
        }

        this.$nextTick(() => {
          if (this.$refs.fieldView) {
            this.$refs.fieldView.init(data.userRes.id, 1, that.dataForm.orgId)
          }
        })
      }
    },
    //居民房屋列表初始化
    resHousesInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/res/houseRes/list`),
        method: 'get',
        params: this.$http.adornParams({
          userId: parseInt(that.dataForm.id)
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let userResHouses = data.userResHouses
          // let u = JSON.parse(JSON.stringify(userResHouses))
          for (let i in userResHouses) {
            let house = userResHouses[i]
            house["initSubarea"] = house.orgIds[house.orgIds.length - 1];
            house["initSubareaName"] = house.subareaName
            house["initHouseId"] = house.houseId
            house["initFullName"] = house.fullName
            house["initRelationship"] = house.relationship
            house["initRelationshipStr"] = house.relationshipStr
            house["initUseType"] = house.useType
            house["initUseTypeStr"] = house.useTypeStr
            house["initNowLive"] = house.nowLive
            house['houses'] = []
            house['subarea'] = house.orgIds[house.orgIds.length - 1];
            house['subareaName'] = house.subareaName;
            house['status'] = 0;
            house['live'] = house.nowLive == 1 ? '是' : '否';
            house['remark'] = house.remark;
            house['manage'] = house.manage;
            that.checkedHouses.push({
              'index': parseInt(i),
              'houseId': house.houseId
            })
          }
          that.resHouses = data.userResHouses,
              that.houseChange(that.resHouses)
        }
      })
    },
    selectInfo() {
      this.userSelect();
      this.houseSelect();
      this.getDictList();
    },
    getDictList() {
      getVirtualDict('political', (dict) => {
        this.selectList.politicalList = dict
      })
      getVirtualDict('religious', (dict) => {
        this.selectList.religiousList = dict
      })
      getDictTree({code: 'marriage'}, (dict) => {
        this.selectList.marriageList = dict
      })
      getDictTree({code: 'education'}, (dict) => {
        this.selectList.educationList = dict
      })
      getCity((e) => {
        this.setNull(e)
        this.cityData = e
      })
      getVirtualDict('militaryType', (dict) => {
        this.selectList.militaryTypeList = dict
      })
      getVirtualDict('insuredStatus', (dict) => {
        this.selectList.insuredStatusList = dict
      })
      getVirtualDict('retirementType', (dict) => {
        this.selectList.retirementTypeList = dict
      })
      getbelongGrid({ orgId: this.$orgId }, function (e) {
        that.selectList.gridList = e
      });
      getVirtualDict('jobType', (dict) => {
        this.selectList.jobTypeList = dict
      })
      getVirtualDict('health', (dict) => {
        this.selectList.healthList = dict
      })
      if (!this.dataForm.id) {
        if (this.$globalData.userInfo.depth == 4) {
          getBelongCommunity(this.$orgId, function (e) {
            that.selectList.orgList = e.map(item => {
              return {
                value: item.value,
                label: item.label,
              }
            })
          });
        } else {
          that.dataForm.communityList = [{
            orgId: this.$orgId,
            orgName: this.$globalData.userInfo.communityName,
          }]
        }
      }
    },
    setNull (list) {
      list.forEach((e) => {
        if (e.districts && !e.districts.length) {
          e.districts = null
        } else if (e.districts && e.districts.length) {
          this.setNull(e.districts)
        }
      })
    },
    userSelect() {
      //户籍性质
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          that.selectList.registryTypeList = data.registryTypes
        }
      })

      //居民标签
      listComRightLabel(function (labels) {
        that.selectList.userCommunityLabelTree = labels
      })

      //身份证类型
      getVirtualDict("idNumberType", function (virtualDictList) {
        that.selectList.idNumberTypeList = virtualDictList
      })
    },
    //房屋信息select加载
    houseSelect() {
      //所有小区
      getbelongSubArea(this.$globalData.userInfo.orgId, function (subAreaList) {
        that.selectList.subareaList = subAreaList

      })

      //居住房屋
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTree`),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.selectList.allHouseTree = data.liveWhichTree
        }
      })

      //与户主关系
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {
          data.relationships.map((r) => {
            r.value = r.value + ""
          })
          that.selectList.relationshipList = data.relationships
        }
      })

      //房屋属性
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/useType`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {

          data.useTypes.map((u) => {
            u.value = u.value + ""
          })
          that.selectList.useTypeList = data.useTypes
        }
      })
    },

    headImgButton(e) {
      console.log(e)
      if (!that.control.disable1) {
        let path = "files/userHeadImg/temporary"
        this.$toast.loading({
          message: '上传中...',
          forbidClick: true,
          duration: 0,
          overlay: true
        })
        upload(e.file, path, null, function (res) {
          // alert(JSON.stringify(res))
          that.show.headImg = getImageStream(res.fileMessage.relativePath)
          that.dataForm.temporaryHeadImg = res.fileMessage.relativePath
          that.dataForm.temporaryHeadImgSuffix = res.fileMessage.suffix
          that.$toast.clear()
        })
      }
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/cardIdIdentified'),
        method: 'post',
        header: {
          appid: this.$globalData.userInfo.depth == 4 ? 'wx245d433a2e482e22' : 'wxcd8dbbf3f2ba3b14'
        },
        data: formdata
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm.name = data.message.name
          this.dataForm.idNumberType = 1
          this.dataForm.idNumber = data.message.id
          this.dataForm.birthday = data.message.birthday
          this.dataForm.sex = data.message.gender == "男" ? 1 : 2
          this.dataForm.nation = data.message.nation
          this.show.nation = data.message.nationality
          this.show.idNumberTypeStr = '身份证'
          if (data.message.province && (!this.dataForm.regAddressPcc || !this.dataForm.regAddressPcc.length || !this.dataForm.id)) {
            this.dataForm.regAddressPcc = [data.message.province, data.message.city, data.message.distinct]
            this.show.regAddressPcc = this.dataForm.regAddressPcc.join('/')
          }
          if (!this.dataForm.regAddress || !this.dataForm.id) {
            this.dataForm.regAddress = data.message.addr
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    goNation() {
      if (!this.control.disable1) {
        // this.$router.push('/nation')
        this.control.nationShow = true
      }
    },
    goNationality() {
      if (!this.control.disable1) {
        this.control.nationalityShow = true
      }
    },
    getImageStream(e) {
      return getImageStream(e)
    },
    showIdNumberType() {
      console.log(this.control.disable1)
      if (!this.control.disable1) {
        this.control.idNumberType = true
      }
    },
    idNumberType(e) {
      this.dataForm.idNumberType = parseInt(e.value)
      this.show.idNumberTypeStr = e.label
      this.control.idNumberType = false
    },
    spouseIdNumberTypeConfirm(e) {
      this.dataForm.spouseIdNumberType = parseInt(e.value)
      this.show.spouseIdNumberType = e.label
      this.control.spouseIdNumberType = false
    },
    showBirthday() {
      if (!this.control.disable1) {
        this.control.birthday = true
      }
    },
    birthdayCalendar(e) {
      let date = formatterMonth(e)
      this.control.birthday = false
      this.show.birthdayStr = date
      this.dataForm.birthday = date
      this.currentBirthDay = date
    },
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    showOrg() {
      if (!this.control.disable2) {
        this.control.orgShow = true
      }
    },
    showRegistryType() {
      if (!this.control.disable2) {
        this.control.registryType = true
      }
    },
    registryType(e) {
      this.dataForm.registryType = parseInt(e.value)
      this.show.registryTypeStr = e.label
      this.control.registryType = false
    },
    orgConfirm(e) {
      this.dataForm.orgId = parseInt(e.value)
      this.show.orgStr = e.label
      this.control.orgShow = false
    },
    showDeathDate() {
      if (!this.control.disabled) {
        this.control.deathDate = true
      }
    },
    showCremationDate() {
      if (!this.control.disabled) {
        this.control.cremationDate = true
      }
    },
    showlabel() {
      if (!this.control.disable2) {
        this.control.label = true
      }
    },
    showHometown() {
      if (!this.control.disabled) {
        this.control.Hometown = true
      }
    },
    showspouseIdNumberType() {
      if (!this.control.disable3) {
        this.control.spouseIdNumberType = true
      }
    },
    showEducation() {
      if (!this.control.disable3) {
        this.control.education = true
      }
    },
    showJobType () {
      if (!this.control.disable3) {
        this.control.jobType = true
      }
    },
    showPolitical() {
      if (!this.control.disable3) {
        this.control.political = true
      }
    },
    showHealth() {
      if (!this.control.disable3) {
        this.control.healthShow = true
      }
    },
    showReligious() {
      if (!this.control.disable3) {
        this.control.religious = true
      }
    },
    showInsuredStatus () {
      if (!this.control.disable4) {
        this.control.insuredStatus = true
      }
    },
    showRetirementType () {
      if (!this.control.disable4) {
        this.control.retirementType = true
      }
    },
    showPension () {
      if (!this.control.disable4) {
        this.control.pensionShow = true
      }
    },
    showMarriage() {
      if (!this.control.disable3) {
        this.control.marriage = true
      }
    },
    showMilitaryType() {
      if (!this.control.disable3) {
        this.control.militaryTypeShow = true
      }
    },
    hometownFinish(value) {
      console.log(value)
    },
    marriageConfirm(value) {
      this.control.marriage = false
      this.show.marriage = value.label
      this.dataForm.marriage = value.value
    },
    militaryTypeConfirm(value) {
      this.control.militaryTypeShow = false
      this.show.militaryTypeStr = value.label
      this.dataForm.militaryType = value.value
    },
    liveConfirm(e) {
      that.resHouses[this.houseIndex].initNowLive = e.value
      that.resHouses[this.houseIndex].live = e.label
      this.control.live = false
    },
    educationConfirm(value) {
      this.control.education = false
      this.show.education = value.label
      this.dataForm.education = value.value
    },
    jobTypeConfirm(value) {
      this.control.jobType = false
      this.show.jobType = value.label
      this.dataForm.jobType = value.value
    },
    politicalConfirm(value) {
      this.control.political = false
      this.show.political = value.label
      this.dataForm.political = value.value
    },
    healthConfirm(value) {
      this.control.healthShow = false
      this.show.healthStr = value.label
      this.dataForm.health = value.value
    },
    religiousConfirm(value) {
      this.control.religious = false
      this.show.religious = value.label
      this.dataForm.religious = value.value
    },
    moveInDateConfirm (value) {
      this.dataForm.moveInDate = formatterDate(value)
      this.control.moveInDate = false
    },
    unemploymentTimeConfirm(value) {
      this.dataForm.unemploymentTime = formatterDate(value)
      this.control.unemploymentTime = false
    },
    employmentConfirm(value) {
      this.control.employment = false
      this.show.employment = value.label
      this.dataForm.employment = value.value
    },
    insuredStatusConfirm(value) {
      this.control.insuredStatus = false
      this.show.insuredStatus = value.label
      this.dataForm.insuredStatus = value.value
    },
    retirementTypeConfirm(value) {
      this.control.retirementType = false
      this.show.retirementType = value.label
      this.dataForm.retirementType = value.value
    },
    pensionConfirm(value) {
      this.control.pensionShow = false
      this.show.pensionStr = value.label
      this.dataForm.pension = value.value
    },
    deathDateCalendar(e) {
      let date = formatterDate(e)
      this.control.deathDate = false
      this.dataForm.deathDate = date

    },
    cremationDateCalendar(e) {
      let date = formatterDate(e)
      this.control.cremationDate = false
      this.dataForm.cremationDate = date

    },
    selectGrid (e) {
      const {selectList, labelCondition, depth} = e
      if (e.labelCondition.length > 0) {
        this.dataForm.gridId = 0
        this.dataForm.miniGridId = 0
        this.selectMsg.gridName = labelCondition[0].label;
        this.dataForm.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
        this.dataForm.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
      }
      this.control.gridShow = false
    },
    //标签级联的关闭
    labelOverlay() {
      // this.setData({ 'control.communityLabelCascader= false })
      let temporaryLabel = that.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }

      temporaryLabel["delete"] = false

      that.labels.push(temporaryLabel)
      that.dataForm.labels.push(temporaryLabel.ids)
    },
    //标签级联重置
    labelClose(e) {
      this.control.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = this.formatLabel(map, false)
      that.temporaryLabel = temporaryLabel
    },
    //删除标签
    removeLabel(value) {
      for (let i in that.dataForm.labels) {
        let label = that.dataForm.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          that.dataForm.labels.splice(i, 1)
        }
      }

      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == value) {
          that.labels[i]["delete"] = true
        }
      }
      that.dataForm.labels = that.dataForm.labels
      that.labels = that.labels
      this.$forceUpdate()
    },
    /**
     * 处理标签显示效果\n
     *
     * @param resLabels
     * @param isOld 是否后端传入的旧数据
     * @returns {[]}
     */
    formatLabel: function (resLabels, isOld) {
      var labels = [];
      if (isOld) {
        for (var i = 0; i < resLabels.length; i++) {
          //是否存在第二级
          var exists2 = false;
          if (resLabels[i].parentId == 0) {
            for (var j = 0; j < resLabels.length; j++) {
              if (resLabels[j].parentId == resLabels[i].id) {
                exists2 = true;
                //是否存在第三级标签
                var exists3 = false;
                for (var k = 0; k < resLabels.length; k++) {
                  if (resLabels[k].parentId == resLabels[j].id) {
                    exists3 = true;
                    labels.push({
                      value: resLabels[k].id,
                      label: resLabels[k].name,
                      name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                      ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                      rightShow: resLabels[k].rightShow,
                      rightUpdate: resLabels[k].rightUpdate
                    })
                  }
                }
                if (!exists3) {
                  //不存在第三级，则加入第二级
                  labels.push({
                    value: resLabels[j].id,
                    label: resLabels[j].name,
                    name: resLabels[i].name + "：" + resLabels[j].name,
                    ids: resLabels[i].id + "," + resLabels[j].id,
                    rightShow: resLabels[j].rightShow,
                    rightUpdate: resLabels[j].rightUpdate
                  })
                }
              }
            }
            if (!exists2) {
              //不存在第二级，则加入第一级
              labels.push({
                value: resLabels[i].id,
                label: resLabels[i].name,
                name: resLabels[i].name,
                ids: resLabels[i].id,
                rightShow: resLabels[i].rightShow,
                rightUpdate: resLabels[i].rightUpdate
              })
            }
          }
        }
      } else {
        let ids = resLabels.value
        let options = resLabels.options;
        if (ids.length == 1) {
          let option = options[0]
          //只有第一级
          labels.push({
            value: option.value,
            label: option.label,
            name: option.label,
            ids: option.value,
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 2) {
          let option1 = options[0]
          let option2 = options[1]
          //不存在第三级，则加入第二级
          labels.push({
            value: option2.value,
            label: option2.label,
            name: option1.label + "：" + option2.label,
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 3) {
          let option1 = options[0]
          let option2 = options[1]
          let option3 = options[2]
          //不存在第三级，则加入第二级
          labels.push({
            value: option3.value,
            label: option3.label,
            name: option1.label + "：" + option2.label + "(" + option3.label + ")",
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        }
      }
      return labels;
    },
    //编辑
    redact() {
      //debugger
      this.control.disabled = false
    },
    // 删除
    moveOut() {
      this.$dialog.confirm({
        message: '确认迁出？',
      }).then(() => {
        if (true) {

          //户籍性质
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/res/moveOutCommunity`),
            method: 'get',
            params: this.$http.adornParams({
              id: that.dataForm.id,
              community: this.$globalData.userInfo.orgId
            })
          }).then(({data}) => {
            if (data.code == 0) {
              that.$toast.success('迁出成功')
              this.$router.go(-1)
            } else {
              that.$toast.fail(data.msg)
            }
          })
        }
      })
    },
    moveAway (id) {
      this.$dialog.confirm({
        title: '搬离',
        message: '确认该人员搬离吗',
      })
          .then(() => {
            this.$http({
              url: this.$http.adornUrl('/wxapp/user/res/houseRes/moveOut'),
              method: 'post',
              params: this.$http.adornParams({
                id: id
              })
            }).then(({data})=> {
              if (data.code == 0) {
                this.$toast.success({message: '成功'})
              }else {
                this.$toast.fail({message: data.msg})
              }
            },err=> {this.$toast.fail({message: err})})
          })
    },
    // 核查
    dataCheck() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/check'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.dataForm.id,
          community: this.dataForm.orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success({message: '核查成功'})
        }else {
          this.$toast.fail({message: data.msg})
        }
      },err=> {this.$toast.fail({message: err})})
    },
    // 前往居民关联房屋新增
    goHouse(item) {
      let data = encodeURIComponent(JSON.stringify(item))
      if (item || !!this.dataForm.id) {
        let code
        if (this.dataForm.registryType == 1 && this.dataForm.nowAddressCode) {
          code = this.dataForm.nowAddressCode
        }
        this.$router.push({path: '/userHouse-info', query: {data: item ? data : null, userId: this.dataForm.id, code}})
      } else {
        this.$toast.fail('请先保存基本信息！')
      }
    },
    //前往房屋
    gotohouse: function (index) {
      let houseId = that.checkedHouses[index].houseId
      this.$store.commit('setHouseId', houseId)
      console.log(that.checkedHouses[0].houseId)
      this.$router.push({path: '/house-view-details', query: {id: houseId}})
    },
    //前往车辆详情
    gotoCar: function (item) {
      if (item) {
        this.$router.push({path: '/resCar-add', query: {id: item.id, subarea: item.subarea, userId: item.userId}})
      } else {
        this.$router.push({path: '/resCar-add', query: {userId: this.dataForm.id}})
      }
    },
    //前往车辆违停记录
    illPark (id, userId, subarea, subareaStr) {
      this.$router.push({path: '/car-add', query: {id: id, subarea, active: '2'}})
      // this.$router.push({path: '/car-illPark', query: {carId: id, userId, subarea, subareaStr, isEdit: true}})
    },
    //前往关系详情
    gotoRelation (item, type, isEdit) {
      let data = encodeURIComponent(JSON.stringify(item))
      if (item || !!this.dataForm.id) {
        this.$router.push({path: '/relation-add', query: {data, userId: this.dataForm.id, type: type,isEdit}})
      } else {
        this.$toast.fail('请先保存基本信息！')
      }
    },
    //添加房屋
    addCard() {
      if (!that.control.disabled) {
        let has = false;//末尾存不存在迁出的
        let index = this.resHouses.length
        for (let i = (this.resHouses.length - 1); i >= 0; i--) {
          let res = this.resHouses[i]
          // 如果存在了迁出,删除记录，则直接插入到迁出删除记录的上一个
          if (((res.moveOut === 0 && res.status === 0)) && has) {
            if (i !== (this.resHouses.length - 1)) {
              index = i + 1
              break
            }
          } else if (res.moveOut === 1 || res.status === 1) {
            has = true
            if (i === 0) {
              // 如果第一个就是迁出或删除的，则直接插入
              index = 0
              break
            }
          }
        }
        this.resHouses.splice(index, 0, {
          id: 0,
          subarea: '',
          houseId: '',
          relationship: 0,
          useType: 0,
          nowLive: 1,
          remark: '',
          houses: [],
          moveOut: 0,
          registryTypeStr: "未知",
          subareaName: "请选择",
          fullName: "请选择",
          relationshipStr: "请选择",
          useTypeStr: "请选择",
          status: 0,
          live: ''
        })
        // this.$set(that.resHouses,that.resHouses)

        this.houseChange(that.resHouses)
      }

    },
    //删除房屋
    removeCard(index) {
      if (!that.control.disabled) {
        this.$dialog.confirm({
          message: '确认删除？',
        }).then(() => {
          if (true) {
            let resHouse = that.resHouses.splice(index, 1)
            resHouse[0].status = 1
            that.resHouses.push(resHouse[0])
            that.resHouses = that.resHouses

            // this.data.dataForm.fetHouses.splice(index, 1)
            let checkedHouses = that.checkedHouses
            let isRemove = false
            let removeIndex = 99
            for (let i in checkedHouses) {
              let checkedHouse = checkedHouses[i]
              if (checkedHouse.index == index) {
                that.checkedHouses.splice(index, 1)
                removeIndex = i
                isRemove = true
                break
              }
            }
            if (isRemove === true) {
              for (let i = removeIndex; i < checkedHouses.length; i++) {
                that.checkedHouses[i].index--
              }
            }
          }
          this.houseChange(that.resHouses)
        })
      }
    },
    //迁出
    moveOutCard(index) {
      if (!that.control.disabled) {
        this.$dialog.confirm({
          message: '确认迁出？',
        }).then(() => {
          if (true) {
            // this.data.dataForm.resHouses.splice(index, 1)
            // 把迁出的数据移到最后面
            let resHouse = that.resHouses.splice(index, 1)
            resHouse[0].moveOut = 1
            that.resHouses.push(resHouse[0])
            that.resHouses = that.resHouses
            //
            let checkedHouses = that.checkedHouses
            let isRemove = false
            let removeIndex = 99
            for (let i in checkedHouses) {
              let checkedHouse = checkedHouses[i]
              if (checkedHouse.index == index) {
                checkedHouses.splice(index, 1)
                removeIndex = i
                isRemove = true
                break
              }
            }
            if (isRemove === true) {
              for (let i = removeIndex; i < checkedHouses.length; i++) {
                checkedHouses[i].index--
              }
            }
          }
          this.houseChange(that.resHouses)
        })

      }
    },
    //房屋信息改变
    houseChange(houseList) {
      if (!houseList) {
        this.control.showNew = false
      } else {
        if (houseList.length === 0) {
          this.control.showNew = true
        } else {
          if (houseList[0].status === 1 || houseList[0].moveOut === 1) {
            this.control.showNew = true
          } else {
            this.control.showNew = false
          }
        }
      }
      that.control.showNew = that.control.showNew
    },

    subareaShow(index) {
      // //debugger
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.subareaPopup = true
      }
    },
    subareaSelect(e) {
      that.resHouses[this.houseIndex].subarea = e.value
      that.resHouses[this.houseIndex].subareaName = e.label
      //清空居住房屋
      that.resHouses[this.houseIndex].fullName = "请选择"
      that.resHouses[this.houseIndex].houseId = ""
      this.resHouses = that.resHouses
      this.control.subareaPopup = false
    },
    //显示居住信息
    houseTreeShow(index) {
      if (!that.control.disabled) {
        // //debugger
        let subarea = that.resHouses[index].subarea
        this.houseIndex = index
        if (!!(subarea)) {
          let allHouseTree = that.selectList.allHouseTree
          allHouseTree.map((t) => {
            if (t.value == subarea) {
              that.selectList.houseTree = t.children
              return;
            }
          })
          this.control.houseTreeCascader = true
          this.checkedHouseIndex = index
        } else {
          this.$toast.fail("请先选择小区")
        }
      }

    },

    houseOnClose() {
      // this.control.houseTreeCascader= false
    },
    //居住信息改变
    houseCascaderChange(e) {
      // //debugger
      let a = (!!(e.selectedOptions[(e.selectedOptions.length - 1)].children))
      console.log(a)
      if (!a) {
        let houseId = null;
        if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
          houseId = e.selectedOptions[(e.selectedOptions.length - 1)].parameter;
        } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
          houseId = e.selectedOptions[(e.selectedOptions.length - 1)].value;
        } else {
          return
        }
        // 防止重复提交一个房间
        if (that.checkedHouses.length > 0) {
          // 先判断有没有
          let exist = false
          let checkedHouses = that.checkedHouses
          //debugger
          for (let i in checkedHouses) {
            let checkedHouse = checkedHouses[i]
            if (checkedHouse.houseId == houseId && this.houseIndex != i) {
              // 如有则提示禁止选择
              that.$toast.fail("房屋信息已存在")
              // that.cancel('房屋信息已存在')
              exist = true
              break
            }
          }
          // 如没有则添加已选择
          if (!exist) {
            let checkedHouses2 = this.checkedHouses
            let save = true
            for (let i in checkedHouses2) {
              let checkedHouse = checkedHouses2[i]
              if (checkedHouse[this.houseIndex] == this.houseIndex) {
                checkedHouse.houseId = houseId
                save = false
              }
            }
            if (save) {
              let a = {'index': this.houseIndex, 'houseId': houseId}
              this.checkedHouses.push(a)
            }

            if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
              this.resHouses[this.houseIndex].houseId = houseId;
              let org = e.selectedOptions[(e.selectedOptions.length - 1)].label;
              let fullName = org.substring(0, org.length - 1)
              this.resHouses[this.houseIndex].fullName = fullName
              this.resHouses = this.resHouses
              // that.$set(that.resHouses,that.resHouses)

            } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
              this.resHouses[this.houseIndex].houseId = houseId
              this.resHouses[this.houseIndex].fullName = e.selectedOptions[(e.selectedOptions.length - 1)].parameter
              this.resHouses = this.resHouses
              // that.$set(that.resHouses,that.resHouses)
            }

          }
        } else {
          if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
            this.resHouses[this.houseIndex].houseId = houseId;
            let org = e.selectedOptions[(e.selectedOptions.length - 1)].label;
            let fullName = org.substring(0, org.length - 1)
            this.resHouses[this.houseIndex].fullName = fullName
            this.resHouses = this.resHouses
            // that.$set(that.resHouses,that.resHouses)
          } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
            this.resHouses[this.houseIndex].houseId = houseId
            this.resHouses[this.houseIndex].fullName = e.selectedOptions[(e.selectedOptions.length - 1)].parameter
            this.resHouses = this.resHouses
            // that.$set(that.resHouses,that.resHouses)
          }
          let a = {'index': this.houseIndex, 'houseId': houseId}
          this.checkedHouses.push(a)
        }
      }
    },
    relationshipShow(index) {
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.relationship = true
      }
    },
    relationshipConfirm(e) {
      that.resHouses[this.houseIndex].relationship = e.value
      that.resHouses[this.houseIndex].relationshipStr = e.label
      that.control.relationship = false
    },
    useTypeShow(index) {
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.useType = true
      }
    },
    useTypeConfirm(e) {
      that.resHouses[this.houseIndex].useType = e.value
      that.resHouses[this.houseIndex].useTypeStr = e.label
      that.control.useType = false
    },
    sexConfirm(value) {
      this.dataForm.sex = value.value
      this.control.sex = false
    },
    //提交
    onSubmit(e) {
      //校验身份证号
      this.$dialog.confirm({
        message: '确认提交？',
      }).then(() => {
        // //debugger
        let check = true
        if (that.dataForm.idNumberType == 1 && check) {
          let numberBool = identityCodeValid(that.dataForm.idNumber)
          if (numberBool == '身份证号格式错误') {
            that.$toast.fail(numberBool)
            check = false
          }
        }
        if (check) {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/save`),
            method: 'post',
            data: this.$http.adornData({
              'cardName':this.dataForm.cardName||'',
              'cardOwnerName':this.dataForm.cardOwnerName||'',
              'cardNumber':this.dataForm.cardNumber||'',
              'id': that.dataForm.id || undefined,
              'name': that.dataForm.name,
              'mobile': that.dataForm.mobile,
              'fixedLine': that.dataForm.fixedLine,
              'sex': that.dataForm.sex,
              'nationality': that.dataForm.nationality,
              'nation': that.dataForm.nation,
              'idNumberType': that.dataForm.idNumberType,
              'idNumber': that.dataForm.idNumber,
              'birthday': that.dataForm.birthday,
              'regAddress': that.dataForm.regAddress,
              'nowAddress': that.dataForm.nowAddress,
              'headImg': that.dataForm.headImg,
              'company': that.dataForm.company,
              'registryType': that.dataForm.registryType,
              'labels': that.dataForm.labels.toString(),
              'death': parseInt(that.dataForm.death),
              'deathDate': that.dataForm.deathDate,
              'resRemark': that.dataForm.resRemark,
              'emMobile': that.dataForm.emMobile,
              'emContact': that.dataForm.emContact,
              'orgId': that.dataForm.orgId,
              'temporaryHeadImg': that.dataForm.temporaryHeadImg,
              'temporaryHeadImgSuffix': that.dataForm.temporaryHeadImgSuffix
            })
          }).then(({data}) => {
            if (data.code == 0) {
              that.uploadHouse(data.userId)
            } else {
              that.$toast.fail(data.msg)
            }
          })
        }
      })
      // setTimeout(function () {
      //   wx.redirectTo({
      //     url: '/pages/common/userRes/index'
      //   })
      // }, 1500)
    },
    //上传房屋
    uploadHouse(userId) {
      let resHousess = []
      for (let index in that.resHouses) {
        let resHouse = that.resHouses[index]
        let resHousee = {
          id: resHouse.id,
          userId: userId,
          subarea: resHouse.subarea,
          houseId: resHouse.houseId,
          relationship: resHouse.relationship,
          useType: resHouse.useType,
          nowLive: resHouse.nowLive,
          remark: resHouse.remark,
          moveOut: resHouse.moveOut,
          status: resHouse.status
        }
        resHousess.push(resHousee)
      }
      //debugger
      let check = true
      for (var i = 0; i < resHousess.length; i++) {
        if (!resHousess[i].subarea) {
          check = false
          this.$toast.fail("所在小区不能为空")
          break
        }
        if (!resHousess[i].houseId) {
          check = false
          this.$toast.fail("居住房屋不能为空")
          break
        }
        if (!resHousess[i].relationship && resHousess[i].relationship != 0) {
          check = false
          this.$toast.fail("与户主关系不能为空")
          break
        }
        if (!resHousess[i].useType && resHousess[i].useType != 0) {
          check = false
          this.$toast.fail("房屋属性不能为空")
          break
        }
      }

      if (check) {

        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/res/houseRes/saveOrUpdate`),
          method: 'post',
          data: this.$http.adornData({
            resHouses: JSON.stringify(resHousess)
          })
        }).then(({data}) => {
          if (data.code == 0) {
            if (that.dataForm.id) {
              that.control.disabled = true
              this.$toast.success("编辑成功")
              this.$router.go(-1)
            } else {
              this.$toast.success("保存成功")
              this.$router.go(-1)
            }
          } else {
            this.$toast.fail(res.msg)
          }
        })
      }
    },
  }
}
</script>

<style lang="scss">
.van-popover {
  position: absolute;
  top: 168px !important;
  right: 30px !important;
}
.gridPopover {
  width: 580px;
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.15);
  border-radius: 8px;
  .van-popover__action-text {
    color: #E9564B;
  }
}
</style>
<style lang="scss" scoped>
.info {
  padding: 0;
}
.required {
  &::before {
    content: '*';
    color: red;
    position: absolute;
    left: 10px;
  }
}
.houseTypeDes {
  color: red;
  font-size: 24px;
  line-height: 26px;
  padding: 10px;
}
.content {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-top: -0.3rem;
  margin-bottom: -0.3rem;
  background-color: #f5f5f5;
}

::v-deep .van-uploader, ::v-deep .van-uploader__input-wrapper {
  width: 100%;
}

.headImg {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}

.van-cell {
  align-items: center;
}

::v-deep .van-collapse-item__content {
  padding: 0;
}

::v-deep .van-field__control--left {
  text-align: left !important;
}

.left > > > .van-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}

.btnPosition {
  position: absolute;
  bottom: 20px;
  width: 720px;
  left: 15px;
}

.ww {
  width: 100%;
}

.userRes {
  height: 160px;
}

.pagemtop {
  margin-top: 30px;
}

.goto {
  float: right;
  margin-left: 20px;
}

.blue {
  color: #007AFF
}

.houses {
  padding-top: 20px;
  background-color: #f5f5f5;
}

.addHouse {
  text-align: center;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.addHouseFont {
  margin-left: 4px;
  font-size: 30px;
  color: #409EFF
}

.iconSize {
  font-size: 200px;
}

.bai {
  height: 200px;
}

.mini-font-size {
  font-size: 28px;
}
.inspect {
  //position: absolute;
  //top: 50%;
  //right: 70px;
  transform: translateY(-3%);
  margin-left: 20px;
  height: 60px;
  line-height: 60px;
  width: 120px;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 9;

}
.form-btn-view {
  position: unset !important;
}
.van-form {
  padding-bottom: 320px;
}
.custom {
  .van-button {
    position: absolute;
    right: 85px;
    top: 20px;
  }
  .check {
    position: absolute;
    right: 220px;
    top: 20px;
  }
  .arrowIcon {
    position: absolute;
    right: 32px;
    top: 0;
    line-height: 88px;
    color: #666;
    font-size: 32px;
  }
}
.scan-title {
  line-height: 96px;
  color: #333;
  padding: 0 30px;
  font-size: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.scan {
  width: 40px;
  height: 40px;
}
.scan-title ::v-deep .van-uploader, ::v-deep .van-uploader__input-wrapper {
  width: unset !important;
}
.headImgBtn ::v-deep .van-uploader, ::v-deep .van-uploader__input-wrapper {
  width: 100% !important;
}
.headPng {
  width: 120px;
  height: 120px;
}
.headImgBtn ::v-deep .van-cell__title span {
  color: #4581F8;
}
.headImgBtn ::v-deep .van-cell__value {
  text-align: right;
}
.form-btn-view {
  margin-top: 20px;
  .btns {
    background-color: #fff;
    margin-top: 0;
    padding: 20px 28px 20px;
  }
}
.addBtn {
  width: 268px;
  height: 258px;
}
.addBtn img {
  width: 268px;
  height: 258px;
}
.houseInfo {
  .content {
    height: 166px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background-color: #fff;
    .top-text {
      width: 100% !important;
      margin-top: 24px;
      //line-height: 84px;
      img {
        width: 24px;
        height: 24px;
        margin-left: 6px;
      }
      .tip {
        position: absolute;
        right: 0;
      }
    }
    .bottom-text {
      width: 100% !important;
    }
  }
  .bottom-left {
    line-height: 88px !important;
    .van-button {
      width: 112px;
      height: 60px;
      color: #4581F8;
      border-radius: 8px;
      border: 2px solid #4581F8;
    }
  }
}
.family {
  margin-top: 40px;
  .content {
    height: 172px !important;
    .content-text {
      height: 120px !important;
      .top-text {
        font-size: 34px !important;
        font-weight: 600;
      }
      .bottom-text {
        width: 6rem !important;
        display: flex !important;
        -webkit-line-clamp: unset !important;
        font-size: 30px !important;
        margin-top: 34px !important;
        span {
          flex: 1;
          display: block;
          width: 7rem !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-left: 10px;
        }
        .tel {
          margin-left: 10px !important;
        }
      }
    }
    .content-img {
      width: 120px !important;
      height: 120px !important;
    }
  }
}
.relation {
  .van-button {
    right: 30px !important;
  }
}
.relationAdd {
  width: 690px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  margin: 60px 30px 0;
  background: rgba(255,255,255,0);
  border-radius: 8px;
  border: 2px dashed #D9D9D9;
  font-size: 28px;
  img {
    width: 28px;
    height: 28px;
    transform: translateY(-4px);
  }
}
.tel {
  width: 40px;
  height: 40px;
}
.carManage {
  .title-right {
    line-height: 70px !important;
    .van-button {
      width: 152px;
      height: 52px;
      //padding: 8px 28px;
      background: #4581F8;
      border-radius: 8px;
      border: none;
      color: #fff;
      margin-bottom: 24px;
    }
  }
  .content {
    height: 134px !important;
    margin-top: unset !important;
    margin-bottom: unset !important;
    .content-text {
      width: 100%;
      height: unset !important;
    }
  }
}
.carText {
  position: relative;
  width: 512px;
  height: 88px;
  line-height: 88px;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  span {
    position: absolute;
    top: 0;
    right: 0;
    width: 88px;
    height: 42px;
    line-height: 42px;
    border-radius: 0px 8px 0px 8px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.oilCar {
  background: rgba(69,129,248,0.12);
  color: #4581F8;
  span{
    background: linear-gradient(90deg, #76A4FF 0%, #4581F8 100%);
    padding: 4px 20px;
  }
}
.newEnergy {
  background: rgba(43,143,50,0.12);
  color: #2B8F32;
  span{
    background: linear-gradient(135deg, #58CC64 0%, #48975F 100%);
    padding: 4px 8px;
  }
}
.car-add {
  position: relative;
  width: 100%;
  height: 226px;
  background: #FFFFFF;
  box-shadow: 0px 0px 26px 0px rgba(234,234,234,0.41);
  img {
    //position: absolute;
    display: block;
    width: 440px;
    height: 214px;
    margin: 0 auto;
    //transform: translateX(42%);
  }
  .van-button {
    position: absolute;
    left: 20%;
    bottom: 24px;
    width: 512px;
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 600;
    background: #4581F8;
    box-shadow: 0px 4px 22px 0px rgba(69,129,248,0.35);
    border-radius: 8px;
    img {
      display: unset !important;
      width: 32px;
      height: 32px;
      line-height: 80px;
      margin-right: 12px;
      margin-bottom: 5px;
    }
  }
}
</style>
